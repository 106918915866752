const arrPhone = [
  { codeCountry: 1, mask: [3, 2, 2, 7], codeCity: [] },
  { codeCountry: 7, mask: [3, 2, 2, 7], codeCity: [] },
  { codeCountry: 81, mask: [4, 4, 6], codeCity: [6, 75, 742] },
  { codeCountry: 82, mask: [4, 4, 6], codeCity: [2, 52, 51] },
  { codeCountry: 86, mask: [4, 4, 6], codeCity: [10, 20, 21] }]
const strReg = arrPhone.map(p =>
  `^(${p.codeCountry})` +
  `(${p.codeCity.join('|') + (p.codeCity.length > 0 ? '|' : '')}\\d{1,3})` +
  `(\\d{0,${p.mask.join('})(\\d{0,') || 16}})`).join('|')
const phoneRegular = RegExp(`${strReg + (strReg.length > 0 ? '|' : '')}^(\\d{1,18})`, '')
export const convertToPhone = (value: string) => {
  if (!value) { return value }
  if (!value.match(/^[\d+]/m)) { return value }
  const x = value.replace(/\D/g, '').match(phoneRegular) || []
  if (x.length) { x.shift() } else { return '+' }
  const i = x.findIndex(index => index)
  if (x[i].length) {
    if (arrPhone.some(p => p.codeCountry === +x[i])) {
      x[i] = x[i]
    } else if (x[i] === '9') {
      x[i] = '7'; x[i + 1] = '9'
    } else { return '+' + x.input }
    value = `+${x[i]}`
  }
  if (x[i + 1]) { value += ` (${x[i + 1]}` }
  if (x[i + 2]) { value += `) ${x[i + 2]}` }
  if (x[i + 3]) { value += `-${x[i + 3]}` }
  if (x[i + 4]) { value += `-${x[i + 4]}` }
  if (x[i + 5]) { value += `-${x[i + 5]}` }
  return value
}
