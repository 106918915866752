import type CustomCard from "../static/components/CustomCard/CustomCard.vue";
import type {
  CustomCardModel,
  CustomCardProps,
} from "@/static/components/CustomCard/CustomCard.interface";
import type Popup from "../static/components/Popup/Popup.vue";
import type {
  PopupModel,
  PopupProps,
} from "@/static/components/Popup/Popup.interface";
import type Popover from "../static/components/Popover/Popover.vue";
import type {
  PopoverModel,
  PopoverProps,
} from "@/static/components/Popover/Popover.interface";
import type DataGrid from "../static/components/DataGrid/DataGrid.vue";
import type {
  DataGridModel,
  DataGridProps,
} from "@/static/components/DataGrid/DataGrid.interface";
import type Cover from "../static/components/Cover/Cover.vue";
import type {
  CoverModel,
  CoverProps,
} from "@/static/components/Cover/Cover.interface";
import type Window from "../static/components/Window/Window.vue";
import type {
  WindowModel,
  WindowProps,
} from "@/static/components/Window/Window.interface";
import type FormDx from "../static/components/Form/Form.vue";
import type {
  FormModel,
  FormProps,
} from "@/static/components/Form/Form.interface";
import type FormHistory from "../static/components/FormHistory/FormHistory.vue";
import type {
  FormHistoryModel,
  FormHistoryProps,
} from "@/static/components/FormHistory/FormHistory.interface";
import type Accordion from "../static/components/Accordion/Accordion.vue";
import type {
  AccordionModel,
  AccordionProps,
} from "@/static/components/Accordion/Accordion.interface";
import type TileView from "../static/components/TileView/TileView.vue";
import type {
  TileViewModel,
  TileViewProps,
} from "@/static/components/TileView/TileView.interface";
import type TreeView from "../static/components/TreeView/TreeView.vue";
import type {
  TreeViewModel,
  TreeViewProps,
} from "@/static/components/TreeView/TreeView.interface";
import type FilePreview from "../static/components/FilePreview/FilePreview.vue";
import type {
  FilePreviewModel,
  FilePreviewProps,
} from "@/static/components/FilePreview/FilePreview.interface";
import type Weather from "../static/components/Weather/Weather.vue";
import type {
  WeatherModel,
  WeatherProps,
} from "@/static/components/Weather/Weather.interface";
const ComponentsListExport: string[] = [
  "CustomCard",
  "Popup",
  "Popover",
  "DataGrid",
  "Cover",
  "Window",
  "FormDx",
  "FormHistory",
  "Accordion",
  "TileView",
  "TreeView",
  "FilePreview",
  "Weather"
];

/* EPCSCustomComponentsExport - экспортирует все модели компонентов для интеграции в Sitronics SPA */
export declare type EPCSCustomComponentsExport =
  | CustomCardModel
  | PopupModel
  | PopoverModel
  | DataGridModel
  | CoverModel
  | WindowModel
  | FormModel
  | FormHistoryModel
  | AccordionModel
  | TileViewModel
  | TreeViewModel
  | FilePreviewModel
  | WeatherModel;
/* EPCSComponentsClassExport - экспортирует все классы компонентов для интеграции в Sitronics SPA */
export declare type EPCSComponentsClassExport =
  | CustomCard
  | Popup
  | Popover
  | DataGrid
  | Cover
  | Window
  | FormDx
  | FormHistory
  | Accordion
  | TileView
  | TreeView
  | FilePreview
  | Weather;
/* EPCSComponentPropsExport - экспортирует все пропсы компонентов для интеграции в Sitronics SPA */
export declare type EPCSComponentPropsExport =
  | CustomCardProps
  | PopupProps
  | PopoverProps
  | DataGridProps
  | CoverProps
  | WindowProps
  | FormProps
  | FormHistoryProps
  | AccordionProps
  | TileViewProps
  | TreeViewProps
  | FilePreviewProps
  | WeatherProps;

export { ComponentsListExport };
