<template>
  <div
    v-for="tplComponent in componentsVue"
    :key="tplComponent?.component.getId()"
    :class="`CUSTOM-${tplComponent.component.getComponentName()}`"
  >
    <component
      :is="componentsList[tplComponent.component.getComponentName()]"
      :key="tplComponent.component.getId()"
      :data="tplComponent.component.getProps()"
      :instance="tplComponent.component"
      :formName="tplComponent.component.name"
      :components="tplComponent.components"
      :allComponents="componentsList"
      class="epcs-components"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

import CustomAsyncComponentsLoader from "sys/customAsyncComponentsLoader";

const allCustomComponents = new CustomAsyncComponentsLoader().getAllStaticComponents();
const componentsList = allCustomComponents;
const props = defineProps(["componentsVue", "name"]);
</script>
