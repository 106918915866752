import config from "@/configs/config";
import { useUser } from "@/store/user";

async function getTransportData(idTransport:string) {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const url = `http://s004.obl.local:3333/ref_transportnyesredstva?identifikatsionnyynomer=eq.${idTransport}`
    xhr.open("GET", url, true);
    xhr.send();
    xhr.onload = function() {
      if (xhr.status < 300){
        resolve(JSON.parse(xhr.response))
      } else { reject(xhr.response) }
    }
    xhr.onerror = function() {
      reject(xhr.response)
    }
  })
}
async function getShips() {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const url = `${config.MAP_URL}/ships-array`
    xhr.open("GET", url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${useUser().getUser('access_token')}`);
    xhr.send();
    xhr.onload = function() {
      if (xhr.status < 300){
        resolve(JSON.parse(xhr.response))
      } else { reject(xhr.response) }
    }
    xhr.onerror = function() {
      reject(xhr.response)
    }
  })
}
async function shipsSIDArray(idTransport:number) {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const url = `http://s004.obl.local:3334/rpc/shipsSIDArray2json`
    let body =
      `sid_array={${idTransport}}` +
      '&maxshipscount=' + encodeURIComponent(365) +
      '&daysfilter=' + encodeURIComponent(90);
    xhr.open("POST", url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Authorization', `Bearer ${useUser().getUser('access_token')}`);
    xhr.send(body);
    xhr.onload = function() {
      if (xhr.status < 300){
        resolve(JSON.parse(JSON.parse(xhr.response))[0])
      } else { reject(xhr.response) }
    }
    xhr.onerror = function() {
      reject(xhr.response)
    }
  })
}
async function getShipHistory(imo:string, startDate?:Date|string, endDate?:Date|string) {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const url = `http://s004.obl.local:5000/api/Ships/getShipHistory?imo=${imo}&startDate=${startDate}&endDate=${endDate}`
    xhr.open("GET", url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
    xhr.onload = function() {
      if (xhr.status < 300){
        resolve(JSON.parse(xhr.response))
      } else { reject(xhr.response) }
    }
    xhr.onerror = function() {
      reject(xhr.response)
    }
  })
}
async function getPort(Ref_Key:string) {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const url = `${config.URL_1C}/odata/standard.odata/Catalog_Пункты/?$format=json&$filter=Ref_Key eq guid'${Ref_Key}'`
    xhr.open("GET", url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${useUser().getUser('access_token')}`);
    xhr.send();
    xhr.onload = function() {
      if (xhr.status < 300){
        resolve(JSON.parse(xhr.response)?.value[0])
      } else { reject(xhr.response) }
    }
    xhr.onerror = function() {
      reject(xhr.response)
    }
  })
}
export default {
  getTransportData,
  getShips,
  shipsSIDArray,
  getShipHistory,
  getPort
}
