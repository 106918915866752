import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { FormProps, TypeDx } from "./Form.interface";
import type dxForm from "devextreme/ui/form";
import type { ValidationResult } from "devextreme/ui/validation_group";

export default class FormDx extends EPCSComponent {
  declare props:FormProps
  isVisible: boolean
  isNew: boolean
  isReadOnly: boolean
  isHideButtons: boolean
  isUpdateForm: boolean
  customParameter: string
  fieldsForm: FormProps["fieldsForm"]
  formValues: any
  formInstance: dxForm|null
  constructor(component: IStructureComponentModel) {
    super(component);
    this.isVisible = typeof this.props.isVisible === "boolean" ? this.props.isVisible : true
    this.isNew = false
    this.isUpdateForm = false
    this.isReadOnly = false
    this.isHideButtons = false
    this.customParameter = ""
    this.fieldsForm = {}
    this.formValues = {}
    this.formInstance = null
  }
  protected setFormInstance(form:dxForm){ this.formInstance = form }
  validate():ValidationResult|undefined{
    return this.formInstance?.validate()
  }
  getIsVisible():boolean { return this.isVisible }
  setIsVisible(value: boolean): void { this.isVisible = value }
  getFormStructure(): FormProps["formStructure"] { return this.props.formStructure }
  setFormStructure(value: FormProps["formStructure"]) { this.props.formStructure = value; }
  getFieldsForm(field?:string):FormProps["fieldsForm"]|TypeDx {
    if (field && this.fieldsForm){
      return <TypeDx>this.fieldsForm[field]
    } else { return <FormProps["fieldsForm"]>this.fieldsForm }
  }
  setFieldsForm(value: FormProps["fieldsForm"]|TypeDx, field?:string) {
    if (field && this.fieldsForm){
      this.fieldsForm[field] = <TypeDx>value
    } else { this.fieldsForm = <FormProps["fieldsForm"]>value }
  }
  getPropFieldsForm(field?:string):FormProps["fieldsForm"]|TypeDx {
    if (field && this.props.fieldsForm){
      return <TypeDx>this.props.fieldsForm[field]
    } else { return <FormProps["fieldsForm"]>this.props.fieldsForm }
  }
  setPropFieldsForm(value: FormProps["fieldsForm"]|TypeDx, field?:string) {
    if (field && this.props.fieldsForm){
      this.props.fieldsForm[field] = <TypeDx>value
    } else { this.props.fieldsForm = <FormProps["fieldsForm"]>value }
  }
  newForm(): void {
    setTimeout(()=> {
      this.props.form = {};
    }, 100)
  }
  setForm(data: FormProps["form"]) {
    this.props.form = data;
  }
  duplicateForm(data: FormProps["form"]): void {
    setTimeout(()=> {
      this.props.form = data;
    }, 100)
  }
  getReadEvent ():FormProps["readEvent"] { return this.props.readEvent }
  setReadEvent (value:FormProps["readEvent"]) { this.props.readEvent = value }
  getReadText ():FormProps["readText"] { return this.props.readText }
  setReadText (value:FormProps["readText"]) { this.props.readText = value }
  getStyle ():FormProps["style"] { return this.props.style }
  setStyle (value:FormProps["style"]) { this.props.style = value }
  getValidateSubmitEvent ():FormProps["validateSubmitEvent"] { return this.props.validateSubmitEvent }
  setValidateSubmitEvent (value:FormProps["validateSubmitEvent"]) { this.props.validateSubmitEvent = value }
  getValidateSubmitText ():FormProps["validateSubmitText"] { return this.props.validateSubmitText }
  setValidateSubmitText (value:FormProps["validateSubmitText"]) { this.props.validateSubmitText = value }
  getIsNew ():boolean {
    return this.isNew
  }
  setIsNew (value:boolean) {
    this.isNew = value
  }
  getIsReadOnly ():boolean {
    return this.isReadOnly
  }
  setIsReadOnly (value:boolean) {
    this.isReadOnly = value
  }
  getIsHideButtons ():boolean {
    return this.isHideButtons
  }
  setIsHideButtons (value:boolean) {
    this.isHideButtons = value
  }
  getIsUpdateForm ():boolean {
    return this.isUpdateForm
  }
  setIsUpdateForm (value:boolean) {
    this.isUpdateForm = value
  }
  getCustomParameter ():string {
    return this.customParameter
  }
  setCustomParameter (value:string) {
    this.customParameter = value
  }
  getFormValues (field?:string) {
    if (field && Object.keys(this.formValues).includes(field)){ return this.formValues[field] }
    return this.formValues
  }
  setFormValues (value:object|any, field?:string) {
    if (field && Object.keys(this.formValues).includes(field)){
      this.formValues[field] = value
    } else {
      this.formValues = value
    }
  }
}
