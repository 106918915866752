import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { FormHistoryProps } from "./FormHistory.interface";

export default class FormHistory extends EPCSComponent {
  declare props:FormHistoryProps
  isVisible: boolean
  constructor(component: IStructureComponentModel) {
    super(component);
    this.isVisible = typeof this.props.isVisible === "boolean" ? this.props.isVisible : true
  }
  getIsVisible():boolean { return this.isVisible }
  setIsVisible(value: boolean): void { this.isVisible = value }
  getFormHistory(): FormHistoryProps["formHistory"] { return this.props.formHistory }
  setFormHistory(value: FormHistoryProps["formHistory"]) { this.props.formHistory = value; }
}
