import Instance from "@/logic/views/instance/instance";
import type Form from "@epcs/sitronics-spa/classes/form/Form";
import type Cover from "@/static/components/Cover/Cover";
import type DataGrid from "@/static/components/DataGrid/DataGrid";
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import type { IInstanceVue } from "@/logic/views/instance/instance.interface";
import {useLocale} from '@/store/locales'


export default class Score extends Instance {
  CoverScore: Cover
  DataGrid: DataGrid
  $l
  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    super(form, Vue, InstancePopup);
    this.$l = useLocale()
    this.CoverScore = <Cover>form.getComponentByName("CoverScore")
    this.DataGrid = <DataGrid>form.getComponentByName("DataGrid")
    this.mount()
    this.emittedEvents()
  }
  mount() {
  }
  emittedEvents() {
  }
}
