import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { WindowProps } from "./Window.interface";

export default class Window extends EPCSComponent {
  declare props:WindowProps
  constructor(component: IStructureComponentModel) {
    super(component);
  }
  getIsVisible ():WindowProps["isVisibleWindow"] { return this.props.isVisibleWindow }
  setIsVisible (value:WindowProps["isVisibleWindow"]) { this.props.isVisibleWindow = value }
  getStyle ():WindowProps["style"] { return this.props.style }
  setStyle (value:WindowProps["style"]) { this.props.style = value }
  getTitle ():WindowProps["title"] { return this.props.title }
  setTitle (value:WindowProps["title"]) { this.props.title = value }
  getContent ():WindowProps["content"] { return this.props.content }
  setContent (value:WindowProps["content"]) { this.props.content = value }
  getButtons ():WindowProps["buttons"] { return this.props.buttons }
  setButtons (value:WindowProps["buttons"]) { this.props.buttons = value }
}
