import config from "@/configs/config";
import {useUser} from "@/store/user";

async function createUser (form:any) {
  const url = `${config.URL_1C}/hs/LK_Qw/CreateUser`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function getUser (keycloakId?:string|null, email?:string|null) {
  let parameter:string = ""
  if (keycloakId) {
    parameter = "keycloakId="+(keycloakId || useUser().getUser('id'))
  } else if (email) {
    parameter = "email="+(email || useUser().getUser('email'))
  }
  if (!parameter.length) { return }
  const url = `${config.URL_1C}/hs/LK_Qw/User?${parameter}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    }
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function getCompanies (NodeCode?:string|null) {
  const id = NodeCode || useUser().getUser('id')
  const url = `${config.URL_1C}/hs/LK_Qw/Companies?NodeCode=${id}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    }
  })
  if (!data.ok) { throw await data.json() }
  const allCompanies = await data.json()
  return allCompanies[Object.keys(allCompanies)[0]]
}
async function resetPassword (form: { keycloakId: string, password: string }, access_token?:string) {
  const url = `${config.URL_1C}/hs/LK_Qw/PasswordChange`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${access_token || useUser().getUser('access_token')}`,
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function sendEmail (form: { email: string }) {
  const url = `${config.URL_1C}/hs/RequestResetPassword`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function resetPasswordByUuid (form: { login: string, resetKey: string }) {
  const url = `${config.URL_1C}/hs/ResetPassword`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
export default {
  createUser, getUser, getCompanies, resetPassword, sendEmail, resetPasswordByUuid
}
