import config from "@/configs/config";
import {useUser} from "@/store/user";

async function submitForm (form:any) {
  const url = `${config.URL_1C}/hs/LK_Qw/CompanyRequest`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  const response = await data.json()
  return response[Object.keys(response)[0]]
}
export default {
  submitForm
}
