import { AsyncComponents } from "@epcs/sitronics-spa/composition/useComponentsList";
import { defineAsyncComponent } from "vue";

export default class CustomAsyncComponentsLoader extends AsyncComponents {
  constructor() {
    super();

    this.allStaticComponents["CustomCard"] = defineAsyncComponent(
      () => import("../static/components/CustomCard/CustomCard.vue")
    );
    this.allStaticComponents["Popup"] = defineAsyncComponent(
      () => import("../static/components/Popup/Popup.vue")
    );
    this.allStaticComponents["Popover"] = defineAsyncComponent(
      () => import("../static/components/Popover/Popover.vue")
    );
    this.allStaticComponents["DataGrid"] = defineAsyncComponent(
      () => import("../static/components/DataGrid/DataGrid.vue")
    );
    this.allStaticComponents["Cover"] = defineAsyncComponent(
      () => import("../static/components/Cover/Cover.vue")
    );
    this.allStaticComponents["Window"] = defineAsyncComponent(
      () => import("../static/components/Window/Window.vue")
    );
    this.allStaticComponents["FormDx"] = defineAsyncComponent(
      () => import("../static/components/Form/Form.vue")
    );
    this.allStaticComponents["FormHistory"] = defineAsyncComponent(
      () => import("../static/components/FormHistory/FormHistory.vue")
    );
    this.allStaticComponents["Accordion"] = defineAsyncComponent(
      () => import("../static/components/Accordion/Accordion.vue")
    );
    this.allStaticComponents["TileView"] = defineAsyncComponent(
      () => import("../static/components/TileView/TileView.vue")
    );
    this.allStaticComponents["TreeView"] = defineAsyncComponent(
      () => import("../static/components/TreeView/TreeView.vue")
    );
    this.allStaticComponents["FilePreview"] = defineAsyncComponent(
      () => import("../static/components/FilePreview/FilePreview.vue")
    );
    this.allStaticComponents["Weather"] = defineAsyncComponent(
      () => import("../static/components/Weather/Weather.vue")
    );
  }
}
