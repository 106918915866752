import {
  type IStructureModel,
  StructuresModelType,
} from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { TileViewModel } from "@/static/components/TileView/TileView.interface";
import type {CoverModel} from "@/static/components/Cover/Cover.interface";
import type {FilePreviewModel} from "@/static/components/FilePreview/FilePreview.interface";
import type { CustomCardModel } from "@/static/components/CustomCard/CustomCard.interface";

const Start: IStructureModel = {
  structureType: StructuresModelType.sform,
  components: [
    {
      priority: 0,
      render: true,
      componentName: "CoverStart",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          // title: "title.welcome",
          style: {
            // padding: "50px 10px 5px",
            borderRadius: "5px",
            height: "-webkit-fill-available",
            right: "0",
            position: "absolute",
            backgroundColor: "var(--header-color-background)",
            backgroundImage: "var(--url-background-start-page)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "auto",
          },
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true
        },
      },
    },
    {
      priority: 1,
      render: true,
      componentName: "Logo",
      parent: "CoverStart",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          type: "img",
          style: {
            display: "flex",
            margin: "auto",
            height: "12rem",
          },
          url: "/Logo-RCC.svg",
          classes: 'center-flex',
        },
      },
    },
    {
      priority: 1,
      render: true,
      componentName: "TileViewStart",
      parent: "CoverStart",
      col: 12,
      component: <TileViewModel>{
        name: "TileView",
        props: {
          isFlex: true,
          style: {
            marginTop: "7rem !important",
            height: "37vh",
          }
        },
      },
    },
    {
      priority: 1,
      render: true,
      componentName: "FerrySchedule",
      parent: "TileViewStart",
      col: 12,
      component: <FilePreviewModel>{
        name: "FilePreview",
        props: {
          switchingMode: true
        },
      },
    },
  ],
};

export { Start };
