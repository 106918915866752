// @ts-ignore
const env = import.meta.env
export default {
  URL_1C: env.VITE_URL_1C,
  MAP_URL: env.VITE_MAP_URL,
  CLOUD_URL: env.VITE_CLOUD_URL,
  URL_KEYCLOAK: env.VITE_URL_KEYCLOAK,
  REALM_KEYCLOAK: env.VITE_REALM_KEYCLOAK,
  CLIENT_KEYCLOAK: env.VITE_CLIENT_KEYCLOAK,
};
