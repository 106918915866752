import type {GridColumns} from "@/static/components/DataGrid/DataGrid.interface";

export const gridColumnsScoreRequest: Array<GridColumns> = [
  {dataField: "Number", caption: "nameColumn.number", dataType: "string", alignment:"left"},
  {dataField: "Date", caption: "nameColumn.billingDate", dataType: "date", alignment:"left"},
  {dataField: "Клиент.Description", caption: "nameColumn.company", dataType: "string", alignment:"left", expand: "Клиент"},
  {dataField: "ДоговорКонтрагента.Номер", caption: "nameColumn.contractNumber", dataType: "number", alignment:"left", expand: "ДоговорКонтрагента"},
  /// {dataField: "status", caption: "nameColumn.status", dataType: "string", alignment:"left"},
  {dataField: "СрокОплаты", caption: "nameColumn.dueDate", dataType: "date", alignment:"left"},
  {dataField: "Услуги.Сумма", caption: "nameColumn.sum", dataType: "number", alignment:"left"},
  // {dataField: "ДоговорКонтрагента.Дата", caption: "nameColumn.dateOfPayment", dataType: "date", alignment:"left", expand: "ДоговорКонтрагента"},
  // {caption: "nameColumn.check", alignment:"left", cellTemplate:"cellTemplateMY"},
]
export const filtersScoreRequest = [
  {
    items: [{ name: "Все", key: "Все"}, { name: "Новые", key: false }, { name: "Просмотренные", key: true }],
    filterableField: "isViewed",
    width: 170
  },
]