import Instance from "@/logic/views/instance/instance";
import type Form from "@epcs/sitronics-spa/classes/form/Form";
import {AppEvents} from "@epcs/sitronics-spa/enums/AppEvents";
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import type { IInstanceVue } from "@/logic/views/instance/instance.interface";
// import type {FieldsForm, FormStructure} from "@/static/components/Form/Form.interface";
import KeycloakController from "@/logic/controllers/AuthController";
import type Cover from "@/static/components/Cover/Cover";
// import type FormDx from "@/static/components/Form/Form";
// import type Popup from "@/static/components/Popup/Popup";
// import type CustomCard from "@/static/components/CustomCard/CustomCard";
import {useUser} from "@/store/user";
import {useCounterparties} from "@/store/counterparties";
import {useActiveCounterparty} from "@/store/activeCounterparty";
import API from "@/api";
import {useLocale} from '@/store/locales'
// import {
//   fieldsFormPopupResetPassword, formStructurePopupResetPassword,
//   formStructurePopupEmail, fieldsFormPopupEmail
// } from "@/logic/pages/login/data";
import { mobileDevise } from "@/helpers/RegExr";

export default class Login extends Instance {
  CoverLogin: Cover
  // FormLogin: FormDx
  // PopupLogin: Popup
  // PopupForm: FormDx
  // ResetPassword: CustomCard
  // temporaryAccessToken: string
  // temporaryKeycloakId: string
  query: any
  $l
  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    super(form, Vue, InstancePopup);
    this.$l = useLocale()
    this.CoverLogin = <Cover>form.getComponentByName("CoverLogin")
    // this.FormLogin = <FormDx>form.getComponentByName("FormLogin")
    // this.PopupLogin = <Popup>form.getComponentByName("PopupLogin")
    // this.PopupForm = <FormDx>form.getComponentByName("PopupForm")
    // this.ResetPassword = <CustomCard>form.getComponentByName("ResetPassword")
    this.query = Vue.currentRouter.query
    // this.temporaryAccessToken = ""
    // this.temporaryKeycloakId = ""
    this.emittedEvents()
    this.mount()
  }
  mount() {
    if (useUser().getUser()) {
      this.Vue.bus.emit(AppEvents.GetComponent, {
        location: { name: "start" },
      });
      return
    }
    if (mobileDevise.test(navigator.userAgent)){
      this.CoverLogin.setStyle({ ...this.CoverLogin.getStyle(),
        padding: "36% 50px", height: "80vh", boxShadow: "none" })
    }
    this.Vue.keycloak.init({ onLoad: "login-required" })
      .then((auth) =>{
        console.log("auth", auth)
        this.Vue.bus.emit("submitLogin", {})
      })
      .catch((reason) =>{
        console.log("reason error", reason)
      });
    // if (this.query?.login && this.query?.resetKey) {
    //   this.Vue.bus.emit("resetPasswordByUuid", { ...this.query }) }
    // this.FormLogin.setForm(this.query)
    // this.ResetPassword.setContent(this.$l.message("title.notRememberPassword")||"")
  }
  emittedEvents() {
    this.Vue.bus.on("submitLogin", async(form:any) => {
      const keycloak = new KeycloakController
      // if (form.login.length && form.password.length){
        this.CoverLogin.startLoad()
        try {
          // const userToken:any = await keycloak.loginUser(form.login, form.password)
          const userToken:any = { access_token: this.Vue.keycloak.token, refresh_token: this.Vue.keycloak.refreshToken}
          try {
            // SET USER
            console.log("keycloak", keycloak)
            const userKeycloak:any = await keycloak.getUser(userToken?.access_token)
            console.log("userKeycloak", userKeycloak)
            // this.temporaryAccessToken = userToken?.access_token
            // this.temporaryKeycloakId = userKeycloak?.id
            // if (userKeycloak?.attributes?.isNewUser && userKeycloak?.attributes?.isNewUser[0]){
            //   this.setParamsPopup({
            //     popupHeader:  this.$l.message("title.setYourNewPassword"),
            //     popupButton:  this.$l.message("btn.create"),
            //     popupFormIsVisible: true,
            //     isVisibleButtons: false,
            //     submitEvent: "submitNewPassword",
            //     formStructure: formStructurePopupResetPassword,
            //     fieldsForm: fieldsFormPopupResetPassword
            //   })
            //   if (!await this.PopupLogin.getPopup()){
            //     // @ts-ignore
            //     setTimeout(()=>this.setParamsPopup({ formStructure: {}, fieldsForm: {}}),300)
            //     throw {message:  this.$l.message("errors.touMustEnterNewPasswordToEnter")}
            //   } else {
            //     delete userKeycloak?.attributes.isNewUser
            //     await keycloak.setUser(userToken?.access_token, userKeycloak)
            //   }
            //   // @ts-ignore
            //   setTimeout(()=>this.setParamsPopup({ formStructure: {}, fieldsForm: {}}),300)
            // }
            const expires_in = new Date((this.Vue.keycloak?.idTokenParsed?.exp||1)*1000)
            useUser().setUser(Object.assign(userToken, userKeycloak, {expires_in}))
            const user1C = await API.profile.getUser(userKeycloak.id)
            useUser().setUser(Object.assign(userToken, userKeycloak, user1C))
            // SET COMPANIES
            const companies1C = await API.profile.getCompanies(userKeycloak.id)
            useCounterparties().setCounterparties(companies1C)
            // SET ACTIVE COMPANY
            const companies = companies1C.filter((company:any)=>company["STATUS"] === "Зарегистрированные организации")
            useActiveCounterparty().setActiveCounterparty(companies.length ? companies[0]['COMPANYID']:"")
            // LOGIN
            this.Vue.bus.emit(AppEvents.GetComponent, {
              location: { name: "start" },
            });
          } catch (e:any) {
            if (e.message){
              this.setMessage(e.message, "error")
            }else {
              this.setMessage(this.$l.message("errors.failedLoginPleaseTryAgainLater")||"", "error")
            }
            // await keycloak.logoutUser(userToken.access_token, userToken.refresh_token)
            useUser().setUser()
            useCounterparties().setCounterparties()
            useActiveCounterparty().setActiveCounterparty()
            this.Vue.keycloak.logout()
          }
        } catch (e:any) {
          if (e.error_description === "Invalid user credentials"){
            this.setMessage( this.$l.message("errors.wrongLoginOrPassword")||"", "error")
          } else {
            this.setMessage(`${this.$l.message("errors.submitLogin")} ${this.$l.message("errors.serverError.text")}`, "error")
          }
        } finally {
          setTimeout(()=>{
            this.CoverLogin.stopLoad()
            // this.FormLogin.setForm(form)
          },100)
        }
      // }
    })
    // this.Vue.bus.on("submitNewPassword", async (popupFormFields:any) => {
    //   try {
    //     await API.profile.resetPassword({keycloakId: this.temporaryKeycloakId, password: popupFormFields?.password}, this.temporaryAccessToken)
    //     this.setMessage(this.$l.message("messages.submitNewPassword")||"")
    //     this.PopupLogin.setPopupResult(true)
    //   } catch (e) {
    //     console.log("Error", e)
    //     this.setMessage(`${this.$l.message("errors.submitNewPassword")} ${this.$l.message("errors.serverError.text")}`, 'error')
    //   }
    // })
    // this.Vue.bus.on("openPopupEmail", async(form:any) => {
    //   const fieldsForm = fieldsFormPopupEmail
    //   if (this.FormLogin.getFormValues("login") && typeof this.FormLogin.getFormValues("login") === "string") {
    //     fieldsForm["email"].value = this.FormLogin.getFormValues("login") }
    //   this.setParamsPopup({
    //     popupHeader:  this.$l.message("title.sendYourselfAnEmailToChangeYourPassword"),
    //     popupButton:  this.$l.message("btn.send"),
    //     popupFormIsVisible: true,
    //     isVisibleButtons: false,
    //     submitEvent: "submitEmail",
    //     formStructure: formStructurePopupEmail,
    //     fieldsForm
    //   })
    //   await this.PopupLogin.getPopup()
    //   // @ts-ignore
    //   setTimeout(()=>this.setParamsPopup({ formStructure: {}, fieldsForm: {}}),300)
    // })
    // this.Vue.bus.on("submitEmail", async(form:any) => {
    //   try {
    //     await API.profile.sendEmail(form)
    //     this.setMessage(this.$l.message("messages.submitEmail")||"")
    //     this.PopupLogin.setPopupResult(true)
    //   }catch (e) {
    //     this.setMessage(`${this.$l.message("errors.submitEmail")} ${this.$l.message("errors.serverError.text")}`, "error")
    //     this.PopupLogin.setPopupResult(false)
    //   }
    // })
    // this.Vue.bus.on("resetPasswordByUuid", async(form:any) => {
    //   try {
    //     await API.profile.resetPasswordByUuid(form)
    //     this.setMessage(this.$l.message("messages.submitEmail")||"")
    //     this.PopupLogin.setPopupResult(true)
    //   } catch (e) {
    //     this.setMessage(`${this.$l.message("errors.submitEmail")} ${this.$l.message("errors.serverError.text")}`, "error")
    //     this.PopupLogin.setPopupResult(false)
    //   }
    // })
  }

  // setParamsPopup (options:setParamsPopupOptions) {
  //   this.PopupLogin.setButtonConfirm(options?.popupButton || "")
  //   if (typeof options.popupFormIsVisible === "boolean") { this.PopupForm.setIsVisible(options.popupFormIsVisible) }
  //   if (options.formStructure){ this.PopupForm.setFormStructure(options.formStructure) }
  //   if (options.fieldsForm){ this.PopupForm.setFieldsForm(options.fieldsForm) }
  //   this.PopupLogin.setPopupTitle(options?.popupHeader || "")
  //   if (typeof options?.isVisibleButtons === "boolean") {
  //     this.PopupLogin.setIsVisibleButtons(typeof options?.isVisibleButtons === "boolean" ? options?.isVisibleButtons : true)
  //     if (!options?.isVisibleButtons) {
  //       if (options?.submitEvent){
  //         this.PopupForm.setValidateSubmitEvent(options?.submitEvent) }
  //       this.PopupForm.setValidateSubmitText(options?.submitText||"btn.save")
  //     }
  //   }
  // }
}

// interface setParamsPopupOptions{
//   popupButton?: string;
//   popupFormIsVisible?: boolean;
//   formStructure?: FormStructure;
//   fieldsForm?: FieldsForm;
//   popupHeader?: string;
//   isVisibleButtons?: boolean;
//   submitEvent?: string;
//   submitText?: string;
// }