import { defineStore } from 'pinia'
import { ref } from "vue";
import zhMessages from 'devextreme/localization/messages/zh.json';
import ruMessages from 'devextreme/localization/messages/ru.json';

import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { localesList, dictionary } from '@/locales';
export const useLocale = defineStore('locales', () => {
  let locales:any = ref(localesList)
  let activeLocale:any = ref(null)
  function getLocale() {
    const storageLocale = sessionStorage.getItem('locale');
    return storageLocale != null ? storageLocale : 'ru';
  }
  function setLocale(savingLocale: string) {
    sessionStorage.setItem('locale', savingLocale);
  }
  function initMessages() {
    loadMessages(zhMessages);
    loadMessages(ruMessages);
    loadMessages(dictionary);
  }
  function changeLocale(e: any) {
    setLocale(e.value);
    document.location.reload();
  }
  function message (locale: string){
    if (!locale) { return }
    const [first, ...rest] = locale.split('.')
    const [baseField, fields] = [first, rest.length > 0? rest.join('.') : null]
    if (!formatMessage(baseField)) { return '' }
    if (fields){
      return fields
        .replace(/\[(\w+)]/g, '.$1').replace(/^\./, '').split('.')
        .reduce((prev:string, curr:any) => prev?.[curr], formatMessage(baseField))
    }
    return formatMessage(baseField)
  }
  //--------------------------------------------------
  activeLocale.value = getLocale();
  initMessages();
  locale(activeLocale.value);
  //--------------------------------------------------
  return { locales, activeLocale, message, changeLocale, getLocale }
})
