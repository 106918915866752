import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {
  CustomCardProps,
  classes,
  url,
  content,
  listData,
  InterfaceWithContent,
  InterfaceWithUrl,
  InterfaceWithClasses,
  title,
  InterfaceWithTitle,
  InterfaceWithList,
  buttonItemOptions,
  InterfaceWithButtonItemOptions
} from "./CustomCard.interface";

export default class CustomCard extends EPCSComponent {
  declare props:CustomCardProps
  constructor(component: IStructureComponentModel) {
    super(component);
    this.props.isVisibleCustomCard = this.props.isVisibleCustomCard !== undefined
        ? this.props.isVisibleCustomCard : true
  }
  getIsVisible ():CustomCardProps["isVisibleCustomCard"] { return this.props.isVisibleCustomCard }
  setIsVisible (value:CustomCardProps["isVisibleCustomCard"]) { this.props.isVisibleCustomCard = value }
  getType ():CustomCardProps["type"] { return this.props.type }
  setType (value:CustomCardProps["type"]) { this.props.type = value }
  getStyle ():CustomCardProps["style"] { return this.props.style }
  setStyle (value:CustomCardProps["style"]) { this.props.style = value }
  getClasses ():classes | undefined { if ("classes" in this.props) { return this.props.classes } }
  setClasses (value:classes) { (this.props as InterfaceWithClasses).classes = value }
  getUrl ():url | undefined { if ("url" in this.props) { return this.props.url } }
  setUrl (value:url) { (this.props as InterfaceWithUrl).url = value }
  getTitle ():title | undefined { if ("title" in this.props) { return this.props.title } }
  setTitle (value:title) { (this.props as InterfaceWithTitle).title = value }
  getListData ():listData | undefined { if ("listData" in this.props) { return this.props.listData } }
  setListData (value:listData) { (this.props as InterfaceWithList).listData = value }
  getContent ():content | undefined { if ("content" in this.props) { return this.props.content } }
  setContent (value:content) { (this.props as InterfaceWithContent).content = value }
  getButtonItemOptions ():buttonItemOptions | undefined { if ("optionsItem" in this.props) { return this.props.optionsItem } }
  setButtonItemOptions (value:buttonItemOptions) { (this.props as InterfaceWithButtonItemOptions).optionsItem = value }
}
