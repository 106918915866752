import type { IStructureModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import { StructuresModelType } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {CoverModel} from "@/static/components/Cover/Cover.interface";
// import type {FormModel} from "@/static/components/Form/Form.interface";
// import type {CustomCardModel} from "@/static/components/CustomCard/CustomCard.interface";
// import type {PopupModel} from "@/static/components/Popup/Popup.interface";
// import {fieldsFormLogin, formStructureLogin} from "@/logic/pages/login/data";
const Login: IStructureModel = {
  structureType: StructuresModelType.sform,
  components: [
    {
      priority: 0,
      render: true,
      componentName: "CoverLogin",
      parent: "",
      col: 6,
      component: <CoverModel>{
        name: "Cover",
        props: {
          // title: "title.entrance",
          // headerImage:"/Logo-RCC.svg",
          style: {
            margin: "auto",
            padding: "30px 50px",
            position: "fixed",
            top: "50%",
            left: "50%",
            borderRadius: "1rem",
            transform: "translate(-50%, -50%)",
            height: "5rem",
            boxShadow: "none"
          },
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true
        },
      },
    },
    // {
    //   priority: 0,
    //   render: true,
    //   componentName: "FormLogin",
    //   parent: "CoverLogin",
    //   col: 12,
    //   component: <FormModel>{
    //     name: "FormDx",
    //     props: {
    //       formStructure: formStructureLogin,
    //       fieldsForm: fieldsFormLogin,
    //       validateSubmitEvent: "submitLogin",
    //       validateSubmitText: "btn.entry",
    //       positionButtons: 'center'
    //     },
    //   },
    // },
    // {
    //   priority: 0,
    //   render: true,
    //   componentName: "PopupLogin",
    //   parent: "",
    //   col: 12,
    //   component: <PopupModel>{
    //     name: "Popup",
    //     props: {
    //       width: "27rem"
    //     }
    //   },
    // },
    // {
    //   priority: 0,
    //   render: true,
    //   componentName: "PopupForm",
    //   parent: "PopupLogin",
    //   col: 12,
    //   component: <FormModel>{
    //     name: "FormDx",
    //     props: {},
    //   },
    // },
    // {
    //   priority: 1,
    //   render: true,
    //   componentName: "ResetPassword",
    //   parent: "CoverLogin",
    //   col: 12,
    //   component: <CustomCardModel>{
    //     name: "CustomCard",
    //     props: {
    //       type: "p",
    //       style: {
    //         marginTop: "1rem",
    //         fontSize: "13px",
    //         textAlign: "center",
    //         color: "var(--header-color)",
    //         cursor: "pointer"
    //       },
    //       classes: 'center-flex',
    //       clickEvent: "openPopupEmail"
    //     },
    //   },
    // },
  ],
};
export { Login };
