import { useLocale } from '@/store/locales'
import type { Column, SummaryTotalItem } from "devextreme/ui/data_grid";
export const get = function (object: any, path: string, separator:string = '.') {
  if (!path) { return null }
  const properties = Array.isArray(path) ? path : path
  .replace(/\[(\w+)]/g, '.$1').replace(/^\./, '')
  .split(separator)
  // @ts-ignore
  return properties.reduce((prev:any, curr:string) => prev?.[curr], object)
}
export function setColumns (columns:Array<Column|any>):Array<Column> {
  const $l = useLocale()
  return columns.map((column:Column)=>{
    if (column?.columns) {
      column.columns = setColumns(column.columns) }
    return {
      ...column,
      allowHeaderFiltering: column?.allowHeaderFiltering || false,
      caption: column.caption ? $l.message(column.caption) : "",
      editorOptions: { ...column.editorOptions, placeholder: column.editorOptions?.placeholder ? $l.message(column.editorOptions?.placeholder) : "" }
    }
  })
}
export function setSummaryTotal (summaryTotalItems:Array<SummaryTotalItem|any>):Array<SummaryTotalItem> {
  const $l = useLocale()
  return summaryTotalItems.map((summaryItem:SummaryTotalItem)=>{
    return {
      ...summaryItem,
      displayFormat: summaryItem?.displayFormat ? $l.message(summaryItem.displayFormat) : ""
    }
  })
}
export function copyObject(o:any,i?:any,r?:any) {
  if(typeof o != "object") return o;
  r = o instanceof Array ? [] : o&&{};
  for(i in o)
    if(o.hasOwnProperty(i))
      r[i] = copyObject(o[i])
  return r
}
export function distinctObject(array: any, key:string) {
  if (array?.length) {
    return array.reduce((acc:{ map: any, items: Array<any> }, item:any) => {
      if (acc.map[get(item, key)]) { return acc }
      acc.map[get(item, key)] = true
      acc.items.push(item)
      return acc
    }, { map: {}, items: [] }).items
  } else { return array }
}