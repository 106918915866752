import {jsPDF} from "jspdf";
import {get} from "@/helpers/toObject";
import {convertToPhone} from "@/helpers/numbers";
import {getTableData} from "@/helpers/requests";
import type {TypeDx} from "@/static/components/Form/Form.interface";
import { useLocale } from '@/store/locales'

export const generateFormFromData = function (form:any, fields:any) {
  Object.keys(fields).map(field => {
    let value
    if (fields[field].nameField){
      value = get(form, fields[field].nameField)
    } else if (form){
      value = get(form, field)
    } else { return }
    if (fields[field]?.typeDx === "dxTextBox" && fields[field]?.mode === "phone") {
      value = convertToPhone(value)
    }
    if (fields[field]?.typeDx === "dxFileUploader") {
      delete fields[field]
      return;
    }
    if (fields[field]?.typeDx === "dxDateBox") {
      if (!(new Date(value) > new Date(0))) { return }
      // const $l = useLocale()
      value = new Date(value).toLocaleDateString(useLocale().getLocale())
    }
    if (value !== undefined) {
      fields[field].value = value
    }
  })
  return fields
}
export const generateStructureFormOfFields = async function (form:any, structure: any, formFields:any, customParameter:string) {
  async function setFields (arrayFields:any) {
    let cargoCodeETSNG: string, cargoCodeGNG: string
    const resultPromise = await Promise.all(arrayFields.fields.map(async (nameField:string)=>{
      const exemplar:TypeDx = form[nameField]
      if (!exemplar) { return }
      if (exemplar?.typeDx === "dxCheckBox") {
        // @ts-ignore
        exemplar.value = exemplar.value ? useLocale().message("special.yes") : useLocale().message("special.no") }
      if (exemplar?.typeDx === "dxMultipleInput" && exemplar.value?.length) {
        // @ts-ignore
        exemplar.value = exemplar.value
          .map((resultRow:any)=>exemplar.gridColumns
            .map((field:any)=> {
              if (field.dataType ==="date") {
                resultRow[field.dataField] = new Date(resultRow[field.dataField])
                  .toLocaleDateString(useLocale().getLocale())}
              return String(get(resultRow, field.dataField) || "").trim()
            })
            .join('/ '))
          .join('; ')
      }
      if (exemplar?.typeDx === "dxDropDownBox") {
        const tableResult = await getTableData({...exemplar, formFields, customParameter})
        if (tableResult) {
          exemplar.value = tableResult
            .map((resultRow:any)=> exemplar.gridColumns
              .map((field:any)=> {
                if (field.dataType ==="date") {
                  resultRow[field.dataField] = new Date(resultRow[field.dataField])
                    .toLocaleDateString(useLocale().getLocale())}
                ////////////////////////////////////////////////////////////////
                ///////////TODO Crutch in code//////////////////////////////////
                // if (nameField === "cargoNameETSNG" && field.dataField === "Code"){
                //   cargoCodeETSNG = String(get(resultRow, field.dataField) || "").trim()
                //   return
                // }
                if (nameField === "cargoNameGNG" && field.dataField === "Code"){
                  cargoCodeGNG = String(get(resultRow, field.dataField) || "").trim()
                  return
                }
                ////////////////////////////////////////////////////////////////
                return String(get(resultRow, field.dataField) || "").trim()
              })
              .filter((field:any)=> String(field).length)
              .join(', '))
            .join('; ')
        }
      }
      if (exemplar?.typeDx === "dxTextArea") {
        if (exemplar.value){
          exemplar.value = exemplar.value.replaceAll("\n", "\n       ")
        }
      }
      return {
        nameField,
        // @ts-ignore
        label: useLocale().message(exemplar.printLabel || exemplar.label || exemplar.text),
        value: exemplar.value
      }
    }))
    arrayFields.fields = resultPromise.filter((field:any)=> field)
    ////////////////////////////////////////////////////////////////
    ///////////TODO Crutch in code//////////////////////////////////
    arrayFields.fields
      .filter((field:any, index:number)=>{
    //     if (field?.nameField === "cargoNameETSNG"){
    //       arrayFields.fields.splice(index+1, 0, { nameField: "cargoCodeETSNG", label: useLocale().message("form.label.cargoCodeForETSG"), value: cargoCodeETSNG})
    //     }
        if (field?.nameField === "cargoNameGNG"){
          arrayFields.fields.splice(index+1, 0, { nameField: "cargoCodeGNG", label: useLocale().message("form.label.cargoCodeForGNG"), value: cargoCodeGNG})
        }
      })
    ////////////////////////////////////////////////////////////////
    if (arrayFields?.groups) {
      arrayFields.groups = await Promise.all(arrayFields.groups
        .filter((instance:any)=> {
          const result = arrayFields.fields
            .find((field: any) => field.nameField === instance.dependentField)?.value
          if (result === useLocale().message("special.no")){ return false } else { return result?.length }
        })
        .map(async(instance:any)=>await setFields(instance))
      )
    }
    return arrayFields
  }
  return await Promise.all(structure.map(async(instance:any)=>{
    return await setFields(instance)
  }))
}
export const generateTextFromStructure = (structure: any):string => {
  let indexField:number = 0
  function getGroup (group:any, iterIndex?: number|null) {
    return group.fields
      .map((field:any)=> {
        if (iterIndex === 0){ indexField++ }
        let resultString = `${iterIndex === 0 ? (indexField<10 ? "  "+indexField : indexField)+".":"     "} ${field?.label}: ${field?.value|| '---'}`
        let strGroup:any = false
        if (group.groups) {
          const findIndex = group.groups.findIndex((g:any)=>g.dependentField === field.nameField)
          if (findIndex >= 0) {
            strGroup = getGroup(group.groups[findIndex], iterIndex ? iterIndex+1 : null)
          }
        }
        return `${resultString}${strGroup?'\n'+strGroup:''}`
      }).join('\n')
    // const result = `        ${group.titleGroup}\n${strFields}`
  }
  return structure.map((exemplar:any)=> getGroup(exemplar, 0)).join('\n')
}
export const printingRequest = (content:any, countLines: number=76, lengthLines:number=80, ) => {
  let text = content.text
  let doc = new jsPDF();
  doc.addFileToVFS("PTSans.ttf", "public/fonts/PT_Sans/PTSans-Bold.ttf");
  doc.addFont("public/fonts/PT_Sans/PTSans-Regular.ttf", "PTSans", "normal");
  doc.addFont("public/fonts/PT_Sans/PTSans-Bold.ttf", "PTSans", "bold");
  doc.addFont("public/fonts/Old_Standard_TT/OldStandardTT-Regular.ttf", "OldStandardTT", "normal");
  doc.addFont("public/fonts/Old_Standard_TT/OldStandardTT-Bold.ttf", "OldStandardTT", "bold");
  doc.addFont("public/fonts/EB_Garamond/static/EBGaramond-Medium.ttf", "EB_Garamond", "Medium");
  doc.addFont("public/fonts/EB_Garamond/static/EBGaramond-ExtraBold.ttf", "EB_Garamond", "ExtraBold");
  // doc.setFont("OldStandardTT", "bold").setFontSize(14)
  //   .text("Приложение № 1", 80, 10, {align:"left"} )
//   doc.setFont("OldStandardTT", "normal").setFontSize(14)
//     .text(`к Договору №___________от «__» _________2022 г.
// на организацию расчетов за морской участок
// пути в железнодорожно-паромном сообении
// Усть-Луга – Балтийск, Балтийск – Усть-Луга`, 80, 20, { align: "justify" } )
  doc.setFont("EB_Garamond", "ExtraBold").setFontSize(14)
    .text(`ООО "${content?.counterparty?.NAME||""}"`, 110, 10, {align:"center"} )
  doc.setFont("EB_Garamond", "Medium").setFontSize(12)
    .text(useLocale().message("title.requestForTransportation")||"", 85, 17, {align:"left"} )
    // .cell(20, 40, 170, 200, ` ЗАПРОС  НА ПЕРЕВОЗКУ`, 1, "center")
  const arrayText = text.split('\n')
    .map((str:string)=>{
      return str.split(' ').reduce((sum,iter)=>{
        if(sum.length === 0){
          return `${iter} `
        } else if (sum.split('\n')[sum.split('\n').length-1].length+iter.length+1>lengthLines){
          return `${sum}\n      ${iter} `
        } else { return `${sum}${iter} ` }
      },"")
    })
  text = arrayText.slice(0, countLines).join('\n')
  doc.setFont("PTSans", "normal").setFontSize(10)
    .text(text, 30, 25, { align: "left" } )
  // doc.setFont("OldStandardTT", "bold").setFontSize(14)
  //   .text("ПОДПИСИ СТОРОН:", 80, 250, {align:"left"} )
  // doc.setFont("PTSans", "normal").setFontSize(12)
  //   .text("От имени Судовладельца:", 40, 260, {align:"left"} )
  doc.setFont("PTSans", "normal").setFontSize(12)
    .text(useLocale().message("title.onBehalfOfTheClient")||"", 50, 260, {align:"left"} )
  // doc.setFont("PTSans", "normal").setFontSize(12)
  //   .text("_____________________/М.В. Лукьянов/", 25, 270, {align:"left"} )
  doc.setFont("PTSans", "normal").setFontSize(12)
    .text("_____________________/_____________/", 25, 270, {align:"left"} )
  // doc.setFont("PTSans", "normal").setFontSize(12)
  //   .text("м.п.", 40, 275, {align:"left"} )
  doc.setFont("PTSans", "normal").setFontSize(12)
    .text(useLocale().message("special.mp")||"", 40, 275, {align:"left"} )
  doc.save(`${useLocale().message("special.namePrintRequest")} ${new Date().toLocaleDateString(useLocale().getLocale())}.pdf`);
}