import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { WeatherProps } from "./Weather.interface";
import type { Coordinate } from "ol/coordinate";
import { useLocale } from '@/store/locales'


export default class Weather extends EPCSComponent {
  declare props:WeatherProps
  constructor(component: IStructureComponentModel) {
    super(component);
    this.props.pathIconWeather = this.props.pathIconWeather || "https://lkk.obl.ru:4004/iconWeather"
  }
  getIsVisible ():WeatherProps["isVisibleWeather"] { return this.props.isVisibleWeather }
  setIsVisible (value:WeatherProps["isVisibleWeather"]) { this.props.isVisibleWeather = value }
  getIsLoaded ():WeatherProps["isLoaded"] { return this.props.isLoaded }
  setIsLoaded (value:WeatherProps["isLoaded"]) { this.props.isLoaded = value }
  getStyle ():WeatherProps["style"] { return this.props.style }
  setStyle (value:WeatherProps["style"]) { this.props.style = value }
  getWeatherData ():WeatherProps["weatherData"] { return this.props.weatherData }
  setWeatherData (value:WeatherProps["weatherData"]) { this.props.weatherData = value }
  async getWeatherPointFromOpenWeatherMap (coordinate:Coordinate):Promise<WeatherProps["weatherData"]> {
    let xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      const url = `https://api.openweathermap.org/data/${this.props.openWeatherMapVersion||'2.5'}/weather?lat=${coordinate[1]}&lon=${coordinate[0]}&lang=${useLocale().getLocale()}&units=metric&appid=${this.props.appid}`
      xhr.open("GET", url, true);
      xhr.send();
      xhr.onload = function() {
        if (xhr.status ===200){
          resolve(JSON.parse(xhr.response))
        } else { reject(xhr.response) }
      }
      xhr.onerror = function() {
        reject(xhr.response)
      }
    })
  }
  getWeatherPoint ():WeatherProps["weatherPoint"] { return this.props.weatherPoint }
  setWeatherPoint (value:WeatherProps["weatherPoint"]) { this.props.weatherPoint = value }
  async getWeatherDataFromOpenWeatherMap (coordinate:Coordinate):Promise<WeatherProps["weatherData"]> {
    let xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      const url = `https://api.openweathermap.org/data/${this.props.openWeatherMapVersion||'2.5'}/forecast/daily?lat=${coordinate[1]}&lon=${coordinate[0]}&lang=ru&units=metric&appid=${this.props.appid}`
      xhr.open("GET", url, true);
      xhr.send();
      xhr.onload = function() {
        if (xhr.status ===200){
          resolve(JSON.parse(xhr.response))
        } else { reject(xhr.response) }
      }
      xhr.onerror = function() {
        reject(xhr.response)
      }
    })
  }
}