import Instance from "@/logic/views/instance/instance";
import type Form from "@epcs/sitronics-spa/classes/form/Form";
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import type { IInstanceVue } from "@/logic/views/instance/instance.interface";
import type { FormStructure, FieldsForm } from "@/static/components/Form/Form.interface";
import type DataGrid from "@/static/components/DataGrid/DataGrid";
import type Cover from "@/static/components/Cover/Cover";
import type FormDx from "@/static/components/Form/Form";
import type CustomCard from "@/static/components/CustomCard/CustomCard";
import type Popup from "@/static/components/Popup/Popup";
import API from "@/api"
import {useLocale} from '@/store/locales'
import {useUser} from "@/store/user";
import {useCounterparties} from "@/store/counterparties";
import {useActiveCounterparty} from '@/store/activeCounterparty';
import {scrollToBottom, scrollToObjectWithoutHeader} from "@/helpers/scroll";
import {
  printingRequest,
  generateFormFromData,
  generateStructureFormOfFields,
  generateTextFromStructure
} from "@/helpers/print";
import {
  fieldsFormFerryRequest,
  formStructureFerryRequest,
  formStructurePopupUnpublished,
  fieldsFormPopupUnpublished,
  formStructurePopupNewShipper,
  fieldsFormPopupNewShipper,
  formStructurePopupNewConsignee,
  fieldsFormPopupNewConsignee
} from './data';

export default class Inquiries extends Instance {
  query: any
  activeCounterparty: any
  activeRequestRefKey: string | null
  activeParentOutgoingPopupEntity: string | null
  CoverListInquiries: Cover
  DataGrid: DataGrid
  Cover: Cover
  FormRequest: FormDx
  NotInquiries: CustomCard
  PopupRequest: Popup
  PopupTitle: CustomCard
  PopupForm: FormDx
  $l
  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    super(form, Vue, InstancePopup);
    this.$l = useLocale()
    this.activeCounterparty = useActiveCounterparty()
    this.CoverListInquiries = <Cover>form.getComponentByName("CoverListInquiries")
    this.DataGrid = <DataGrid>form.getComponentByName("DataGrid")
    this.Cover = <Cover>form.getComponentByName("Cover")
    this.FormRequest = <FormDx>form.getComponentByName("Form")
    this.NotInquiries = <CustomCard>form.getComponentByName("NotInquiries")
    this.PopupRequest = <Popup>form.getComponentByName("PopupRequest")
    this.PopupTitle = <CustomCard>form.getComponentByName("PopupTitle")
    this.PopupForm = <FormDx>form.getComponentByName("PopupForm")
    this.activeRequestRefKey = null
    this.activeParentOutgoingPopupEntity = null
    this.FormRequest.setCustomParameter(this.activeCounterparty.getActiveCounterparty())
    this.loadTable()
    this.emittedEvents()
    this.query = this.Vue.currentRouter.query
    setTimeout(()=> {
      if (Object.keys(this.query).length && this.query?.request) {
        this.Vue.bus.emit("openCover", { key: this.query?.request })
      }
    },3000)
  }
  loadTable() {
    this.DataGrid.startLoad()
    API.inquiries.getInquiries(this.activeCounterparty.getActiveCounterparty(), useUser().getUser('id'))
      .then(data => {
        data.forEach((_:any,j:any) => {
          const result = data[j]["CargoArray"].map((item:any)=>item["cargoName"])
          data[j]['Cargos'] = result.length > 1 ? result.join(", ") : result.length ? result : ""
          });
        this.DataGrid.setDataSource(data)
      })
      .catch(e =>{
        if (e === "no counterparty") {
          this.NotInquiries.setContent(this.$l.message("title.notInquiries")||"")
          this.NotInquiries.setIsVisible(true)
          this.DataGrid.setIsVisible(false)
        } else {
          this.setMessage(`${this.$l.message("errors.getInquiries")} ${this.$l.message("errors.serverError.text")}`, 'error')
          console.log("getInquiries", e)
        }
      })
      .finally(()=> setTimeout(()=>{
        this.DataGrid.stopLoad()
      },300))
  }
  emittedEvents() {
    this.Vue.bus.on("updateTable", () => {
      this.loadTable()
    })
    this.Vue.bus.on("newCover", () => {
      try {
        this.Cover.startLoad()
        this.activeRequestRefKey = null
        this.Cover.fullScreenCover()
        this.FormRequest.newForm()
        this.FormRequest.setIsNew(true)
        this.FormRequest.setIsHideButtons(false)
        this.FormRequest.setIsReadOnly(false)
      } catch (e) {
        console.log("error", e)
      } finally {
        setTimeout(()=>{
          this.Cover.stopLoad()
        },1000)
      }
    })
    this.Vue.bus.on("openCover", async (e:any) => {
      this.activeRequestRefKey = e.key
      await this.Vue.router.push({query: {...this.Vue.currentRouter.query, request: this.activeRequestRefKey}})
      this.DataGrid.setFocusedRowKey(this.activeRequestRefKey)
      this.Cover.startLoad()
      const response = await this.getCovers(this.activeRequestRefKey||null)
      if (response){
        this.Cover.openCover()
        this.FormRequest.setForm(response)
        this.FormRequest.setIsNew(false)
      } else {
        this.Cover.closeCover()
        this.activeRequestRefKey = null
      }
      // this.CoverListInquiries.setEventButton("duplicateEvent", "duplicateCover")
      this.Cover.stopLoad()
      setTimeout(()=> {
        this.Vue.bus.emit("updateFieldEvent", { field: "departurePoint" })
        this.Vue.bus.emit("updateFieldEvent", { field: "destinationPoint" })
      },100)
      setTimeout(()=> {
        scrollToObjectWithoutHeader('.content-body', '#componentCover')
      }, 200)
    })
    this.Vue.bus.on("closeCover", async() => {
      try{
        if (this.FormRequest.getIsUpdateForm()) {
          this.setParamsPopup({
            popupButtonConfirm: this.$l.message("btn.close"),
            popupTitle: this.$l.message("title.areYouSureCloseUnsavedData"),
            popupFormIsVisible: false
          })
          if (!await this.PopupRequest.getPopup()){ return }
        }
        this.Cover.closeCover()
        this.activeRequestRefKey = null
        await this.Vue.router.push({query:{}})
      }catch (e) {
        console.log("error", e)
      }
    })
    this.Vue.bus.on("updateCover", async () => {
      this.Cover.startLoad()
      const response = await this.getCovers(this.activeRequestRefKey||null)
      this.FormRequest.setForm(response)
      this.Cover.stopLoad()
    })
    this.Vue.bus.on("duplicateCover", async (e:any) => {
      this.Cover.startLoad()
      const response = await this.getCovers(this.activeRequestRefKey||e.key||null)
      if (response){
        delete response.Ref
        this.FormRequest.setIsReadOnly(false)
        this.Cover.duplicateCover()
        this.FormRequest.duplicateForm(response)
        this.FormRequest.setIsNew(true)
        this.FormRequest.setIsHideButtons(false)
        this.setMessage(this.$l.message("messages.duplicateCover")||"")
      }
      this.activeRequestRefKey = null
      setTimeout(()=>{
        this.Cover.stopLoad()
      },1000)
    })
    this.Vue.bus.on("resizeCover", () => {})
    this.Vue.bus.on("printCover", async () => {
      this.Cover.startLoad()
      const response = await this.getCovers(this.activeRequestRefKey||null)
      if (response){
        const resultFormForPrint = generateFormFromData(response,
          JSON.parse(JSON.stringify(fieldsFormFerryRequest)))
        const resultStructureForPrint = await generateStructureFormOfFields(resultFormForPrint,
          JSON.parse(JSON.stringify(formStructureFerryRequest)),
          this.FormRequest.getFormValues(),
          this.activeCounterparty.getActiveCounterparty())
        const resultTextForPrint = generateTextFromStructure(resultStructureForPrint)
        const counterparty = useCounterparties().getCounterparties(this.activeCounterparty.getActiveCounterparty())
        printingRequest({text: resultTextForPrint, counterparty})
      } else {
        this.Cover.closeCover()
        this.activeRequestRefKey = null
      }
      this.Cover.stopLoad()
    })
    this.Vue.bus.on("submitFerryRequest", async (form:any) => {
      try{
        this.Cover.startLoad()
        if (this.activeRequestRefKey) {
          form['Ref_Key'] = this.activeRequestRefKey
        }
        form['keycloakId'] = useUser().getUser('id')
        form['counterparty'] = this.activeCounterparty.getActiveCounterparty()
        const response = await API.ferryTransportation.submitForm(form)
        if (this.activeRequestRefKey?.length) {
          this.setMessage(this.$l.message("messages.applicationUpdated")||"")
        } else {
          this.setMessage(this.$l.message("messages.applicationSavedAsDraft")||"")
        }
        this.activeRequestRefKey = response.Ref
        const newResponse = await this.getCovers(this.activeRequestRefKey||null)
        this.FormRequest.setForm(newResponse)
        this.FormRequest.setIsNew(false)
        setTimeout(()=> {
          this.Vue.bus.emit("updateFieldEvent", { field: "departurePoint" })
          this.Vue.bus.emit("updateFieldEvent", { field: "destinationPoint" })
        },100)
      } catch (e:any) {
        this.setMessage(`${this.$l.message("errors.submitFerryRequest")} ${this.$l.message("errors.serverError.text")}`, 'error')
        console.log("getCovers", e)
      }finally {
        this.Cover.stopLoad()
        setTimeout(()=> scrollToBottom(".form-container"),10)
        this.loadTable()
      }
    })
    this.Vue.bus.on("publishFerryRequest", async () => {
      try{
        if (this.activeRequestRefKey) {
          await API.ferryTransportation.setStatus(this.activeRequestRefKey)
          this.FormRequest.setIsNew(false)
          this.FormRequest.setIsReadOnly(true)
          this.loadTable()
          setTimeout(()=> this.setMessage(this.$l.message("messages.publishFerryRequest")||""),3000)
        }
      }catch (e) {
        this.setMessage(`${this.$l.message("errors.publishFerryRequest")} ${this.$l.message("errors.serverError.text")}`, 'error')
      }
    })
    this.Vue.bus.on("unpublishedFerryRequest", async () => {
      this.setParamsPopup({
        popupButtonConfirm: this.$l.message("btn.cancelRequest"),
        popupTitle: this.$l.message("title.submitUnpublishedFerryRequest"),
        popupFormIsVisible: true,
        isVisibleButtons: false,
        submitEvent: "submitUnpublishedFerryRequest",
        formStructure: formStructurePopupUnpublished,
        fieldsForm: fieldsFormPopupUnpublished
      })
      await this.PopupRequest.getPopup()
      // @ts-ignore
      setTimeout(()=>this.setParamsPopup({ formStructure: {}, fieldsForm: {}}),300)
    })
    this.Vue.bus.on("submitUnpublishedFerryRequest", async (popupFormFields) => {
      try {
        const form = {
          requestId: this.activeRequestRefKey,
          keycloakId: useUser().getUser('id'),
          ...popupFormFields
        }
        await API.ferryTransportation.withdrawnApplication(form)
        this.setMessage(this.$l.message("messages.submitUnpublishedFerryRequest")||"")
        this.PopupRequest.setPopupResult(true)
        setTimeout(() => {
          this.Cover.closeCover()
          this.activeRequestRefKey = null
          this.loadTable()
        }, 500)
      } catch (e) {
        console.log("Error", e)
        this.setMessage(`${this.$l.message("errors.submitUnpublishedFerryRequest")} ${this.$l.message("errors.serverError.text")}`, 'error')
      }
    })
    this.Vue.bus.on("deleteFerryRequest", async () => {
      try{
        if (this.activeRequestRefKey) {
          this.setParamsPopup({
            popupButtonConfirm: this.$l.message("btn.delete"),
            popupTitle:  this.$l.message("title.deleteFerryRequest"),
            popupFormIsVisible: false
          })
          if (await this.PopupRequest.getPopup()) {
            const response = await API.ferryTransportation.deleteRequest(this.activeRequestRefKey)
            if (response) {
              this.setMessage(this.$l.message("messages.deleteFerryRequest")||"")
              this.Cover.closeCover()
              this.activeRequestRefKey = null
              setTimeout(()=> this.loadTable(),500)
            } else {
              this.setMessage(`${this.$l.message("errors.deleteFerryRequest")} ${this.$l.message("errors.serverError.text")}`, 'error')
            }
          }
        }
      }catch (e) {
        this.setMessage(`${this.$l.message("errors.deleteFerryRequest")} ${this.$l.message("errors.serverError.text")}`, 'error')
      }
    })
    this.Vue.bus.on("createNewShipper", async () => {
      this.setParamsPopup({
        popupHeader: this.$l.message("title.creation"),
        popupButtonConfirm: this.$l.message("btn.create"),
        popupTitle: this.$l.message("title.createNewCarrier"),
        popupFormIsVisible: true,
        isVisibleButtons: false,
        submitEvent: "submitNewShipper",
        formStructure: formStructurePopupNewShipper,
        fieldsForm: fieldsFormPopupNewShipper
      })
      await this.PopupRequest.getPopup()
      // @ts-ignore
      setTimeout(()=>this.setParamsPopup({ formStructure: {}, fieldsForm: {}}),300)
    })
    this.Vue.bus.on("submitNewShipper", async (popupFormFields) => {
      try {
        const form = {
          counterparty: this.activeCounterparty.getActiveCounterparty(),
          keycloakId: useUser().getUser('id'),
          "shipper": true,
          "Consignee": false,
          ...popupFormFields
        }
        await API.carrier.createElementsOfCatalog(form)
        this.setMessage(this.$l.message("messages.submitNewCarrier")||"")
        this.PopupRequest.setPopupResult(true)
      } catch (e) {
        console.log("Error", e)
        this.setMessage(`${this.$l.message("errors.submitNewCarrier")} ${this.$l.message("errors.serverError.text")}`, 'error')
      }
    })
    this.Vue.bus.on("createNewConsignee", async () => {
      this.setParamsPopup({
        popupHeader: this.$l.message("title.creation"),
        popupButtonConfirm: this.$l.message("btn.create"),
        popupTitle: this.$l.message("title.createNewCarrier"),
        popupFormIsVisible: true,
        isVisibleButtons: false,
        submitEvent: "submitNewConsignee",
        formStructure: formStructurePopupNewConsignee,
        fieldsForm: fieldsFormPopupNewConsignee
      })
      await this.PopupRequest.getPopup()
      // @ts-ignore
      setTimeout(()=>this.setParamsPopup({ formStructure: {}, fieldsForm: {}}),300)
    })
    this.Vue.bus.on("submitNewConsignee", async (popupFormFields) => {
      try {
        const form = {
          counterparty: this.activeCounterparty.getActiveCounterparty(),
          keycloakId: useUser().getUser('id'),
          "Consignee": true,
          "shipper": false,
          ...popupFormFields
        }
        await API.carrier.createElementsOfCatalog(form)
        this.setMessage(this.$l.message("messages.submitNewCarrier")||"")
        this.PopupRequest.setPopupResult(true)
      } catch (e) {
        console.log("Error", e)
        this.setMessage(`${this.$l.message("errors.submitNewCarrier")} ${this.$l.message("errors.serverError.text")}`, 'error')
      }
    })
    this.Vue.bus.on("updateFieldEvent", async (event:any) => {
      const formValues = this.FormRequest.getFormValues()
      switch (event.field) {
        case "destinationPoint": {
          const departurePoint = this.FormRequest.getFieldsForm("departurePoint")
          if (departurePoint && "filter" in departurePoint) {
            if (formValues["destinationPoint"]){
              departurePoint.filter = [["ПунктНазначения.Ref_Key", "=", "destinationPoint"], "and", [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true]]
            } else { departurePoint.filter = [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true] }
          }
          break;
        }
        case "departurePoint":{
          const destinationPoint = this.FormRequest.getFieldsForm("destinationPoint")
          if (destinationPoint && "filter" in destinationPoint) {
            if (formValues["departurePoint"]){
              destinationPoint.filter = [["ПунктОтправки.Ref_Key", "=", "departurePoint"], "and", [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true]]
            } else { destinationPoint.filter = [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true] }
          }
          break;
        }
      }
    })
  }
  async getCovers (key:string|null){
    try {
      if (!key) { return }
      const data = await API.ferryTransportation.getCover(key)
      // this.activeRequestRefKey = data.Ref
      const notEditableStatuses = ["На согласовании", "Отклонена", "Отменена", "Согласована", "Удалена"]
      this.FormRequest.setIsReadOnly(notEditableStatuses.includes(data['СтатусСтрока']))
      // if (data['Статус']==="146dd734-9af2-11ed-9634-0cc47a227aee") {
      //   this.FormRequest.setReadText("Сохранить")
      //   this.FormRequest.setReadEvent("submitFerryRequest")
      // }
      // if (data['Статус']==="84c2ea96-81e4-11ed-851f-0cc47a227aee") {
      //   this.FormRequest.setReadText("Отменить заявку")
      //   this.FormRequest.setReadEvent("unpublishedFerryRequest")
      // }
      // this.FormRequest.setIsHideButtons
      return data
    }catch (e:any) {
      this.setMessage(`${this.$l.message("errors.getCovers")} ${this.$l.message("errors.serverError.text")}`, 'error')
      console.log("getCovers", e)
      return null
    }
  }
  setParamsPopup (options:setParamsPopupOptions) {
    this.PopupRequest.setButtonConfirm(options?.popupButtonConfirm || "")
    this.PopupRequest.setButtonReject(options?.popupButtonReject || "")
    this.PopupTitle.setContent(options?.popupTitle || "")
    if (typeof options.popupFormIsVisible === "boolean") { this.PopupForm.setIsVisible(options.popupFormIsVisible) }
    if (options.formStructure){ this.PopupForm.setFormStructure(options.formStructure) }
    if (options.fieldsForm){ this.PopupForm.setPropFieldsForm(options.fieldsForm) }
    this.PopupRequest.setPopupTitle(options?.popupHeader || "")
    this.PopupRequest.setIsVisibleButtons(typeof options?.isVisibleButtons === "boolean" ? options?.isVisibleButtons : true)
    this.PopupRequest.setIsHideOnOutsideClick(typeof options?.isHideOnOutsideClick === "boolean" ? options?.isHideOnOutsideClick : true)
    if (typeof options?.isVisibleButtons === "boolean" && !options?.isVisibleButtons) {
      if (options?.submitEvent){
        this.PopupForm.setValidateSubmitEvent(options?.submitEvent)
      }
      this.PopupForm.setValidateSubmitText(options?.submitText||"btn.save")
    }
  }
}
interface setParamsPopupOptions{
  popupButtonConfirm?: string;
  popupButtonReject?: string;
  popupTitle?: string;
  popupFormIsVisible?: boolean;
  formStructure?: FormStructure;
  fieldsForm?: FieldsForm;
  popupHeader?: string;
  isVisibleButtons?: boolean;
  isHideOnOutsideClick?: boolean;
  submitEvent?: string;
  submitText?: string;
}
