// @ts-ignore
import type Form from "@epcs/sitronics-spa/classes/form/Form";
import type {
  IInstance,
  IInstanceVue,
} from "@/logic/views/instance/instance.interface";
// @ts-ignore
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import notify from "devextreme/ui/notify";
import type {ToastType} from "devextreme/ui/toast";

export default class Instance implements IInstance {
  form: Form;
  Vue: IInstanceVue;
  InstancePopup: IEPCSPopup;

  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    this.form = form;
    this.Vue = Vue;
    this.InstancePopup = InstancePopup;
  }

  destroy() {
    return;
  }
  setMessage (message:string, type:ToastType='success', positionOf:string="window", position:string="0 5", displayTime:number=3000) {
    notify({message, width: "max-content", position: { of: positionOf, my: 'center top', at: 'center top', offset: position } }, type, displayTime);
  }
}
