import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import MainView from "../views/MainView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "start",
    component: MainView,
    meta: {
      logicName: "Start",
    },
  },
  {
    path: "/login",
    name: "login",
    component: MainView,
    meta: {
      logicName: "Login",
      noToolbar: true
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: MainView,
    meta: {
      logicName: "Profile",
    },
  },
  {
    path: "/calculator",
    name: "calculator",
    component: MainView,
    meta: {
      logicName: "Calculator",
    },
  },
  {
    path: "/contracts",
    name: "contracts",
    component: MainView,
    meta: {
      logicName: "Contracts",
    },
  },
  {
    path: "/inquiries/:request?",
    name: "inquiries",
    component: MainView,
    meta: {
      logicName: "Inquiries",
    },
  },
  // {
  //   path: "/maps",
  //   name: "maps",
  //   component: MainView,
  //   meta: {
  //     logicName: "Maps",
  //   },
  // },
  {
    path: "/score",
    name: "score",
    component: MainView,
    meta: {
      logicName: "Score",
    },
  },
];

const router = (base: string) => {
  return createRouter({
    history: createWebHistory(base),
    routes,
  });
};

export default router;
