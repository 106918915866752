import {defineStore} from "pinia";
import {ref} from "vue";

export const useActiveCounterparty = defineStore('activeCounterparty', () => {
  // @ts-ignore
  let activeCounterparty:any = ref(localStorage.getItem('activeCounterparty')||"")
  function getActiveCounterparty () {
    return activeCounterparty.value
  }
  function setActiveCounterparty (value?: string):void{
    if (value) {
      // @ts-ignore
      localStorage.setItem(this.$id, value)
      activeCounterparty.value = value
    } else {
      // @ts-ignore
      localStorage.removeItem(this.$id)
    }
  }
  return { activeCounterparty, getActiveCounterparty, setActiveCounterparty }
})
