import {defineStore} from "pinia";
import {ref} from "vue";

export const useCounterparties = defineStore('counterparties', () => {
  // @ts-ignore
  let counterparties:any = ref(localStorage.getItem('counterparties')? JSON.parse(localStorage.getItem('counterparties')) :'')
  function getCounterparties (id?: string){
    if (id){
      return counterparties.value.find((counterparty:any)=>counterparty["COMPANYID"]===id)
    } else {
      return counterparties.value
    }
  }
  function setCounterparties (value?: string|object, field?:string, id?: number):void{
    if (value) {
      if (field&&id){
        counterparties.value[id][field] = value
      } else {
        counterparties.value = value
      }
      // @ts-ignore
      localStorage.setItem(this.$id, JSON.stringify(counterparties.value));
    } else {
      // @ts-ignore
      localStorage.removeItem(this.$id)
    }
  }
  return { counterparties, getCounterparties, setCounterparties }
})
