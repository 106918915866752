import {
  type IStructureModel,
  StructuresModelType,
} from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {CoverModel} from "@/static/components/Cover/Cover.interface";
import type { FormModel } from "@/static/components/Form/Form.interface";
import type { CustomCardModel } from "@/static/components/CustomCard/CustomCard.interface";
import type { DataGridModel } from "@/static/components/DataGrid/DataGrid.interface";
import { formStructureCalculator, fieldsFormCalculator, gridColumnsRateList } from "@/logic/pages/calculator/data";

const Calculator: IStructureModel = {
  structureType: StructuresModelType.sform,
  components: [
    {
      priority: 0,
      render: true,
      componentName: "CoverCalculator",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          title: "title.calculator",
          style: {right: "0", position: "absolute"},
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true,
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "FormCalculator",
      parent: "CoverCalculator",
      col: 12,
      component: <FormModel>{
        name: "FormDx",
        props: {
          formStructure: formStructureCalculator,
          fieldsForm: fieldsFormCalculator,
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "FormCalculate",
      parent: "CoverCalculator",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          type: "buttonItem",
          style: {
            margin: "1rem"
          }
        }
      }
    },
    {
      priority: 0,
      render: true,
      componentName: "RateList",
      parent: "CoverCalculator",
      col: 12,
      component: <DataGridModel>{
        name: "DataGrid",
        props: {
          valueKey: 'Rate_UID',
          gridColumns: gridColumnsRateList,
          onSelectionChangedEvent: "selectRate",
          selectionMode: "multiple",
          isHiddenToolbar: true,
          isHiddenSearch: true,
          isHiddenFilterRow: true,
          isHiddenPager:true,
          style: {
            height: "13rem"
          },
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "SubmitRate",
      parent: "CoverCalculator",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          type: "buttonItem",
          style: {
            margin: "1rem"
          }
        }
      }
    },
  ],
};

export { Calculator };
