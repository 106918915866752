import config from "@/configs/config";
import {useUser} from "@/store/user";

export interface ICalculateTheCost {
  "departurePoint": string,
  "destinationPoint": string,
  "cargoWeight": number,
  "unit": string,
  "volume": number,
  "preKerridge": boolean,
  "cargoPickupAddress": string,
  "departureDate": Date
}
async function calculateTheCost (form:ICalculateTheCost, keycloak:string) {
  const url = `${config.URL_1C}/hs/LK_Qw/CalculateRates?keycloak=${keycloak}`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
export default {
  calculateTheCost
}
