import type Instance from "@/logic/views/instance/instance";
import Start from "@/logic/pages/start/start.logic";
import Login from "@/logic/pages/login/login.logic";
import Profile from "@/logic/pages/profile/profile.logic";
import Calculator from "@/logic/pages/calculator/calculator.logic";
import Inquiries from "@/logic/pages/inquiries/Inquiries.logic";
import Score from "@/logic/pages/score/score.logic";

interface LogicMap {
  [structureName: string]: typeof Instance;
}

const allInstances: LogicMap = {
  Start,
  Login,
  Profile,
  Calculator,
  Inquiries,
  Score,
};

export default allInstances;
