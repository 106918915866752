import type Form from "@epcs/sitronics-spa/classes/form/Form";
import type { IInstanceVue } from "@/logic/views/instance/instance.interface";
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import Instance from "@/logic/views/instance/instance";
import type Cover from "@/static/components/Cover/Cover";
import type FormDx from "@/static/components/Form/Form";
import type Accordion from "@/static/components/Accordion/Accordion";
import type Popup from "@/static/components/Popup/Popup";
import type CustomCard from "@/static/components/CustomCard/CustomCard";
import API from "@/api";
import {useLocale} from '@/store/locales'
import {useUser} from "@/store/user";
import {useCounterparties} from "@/store/counterparties";
import {useActiveCounterparty} from "@/store/activeCounterparty";
import {scrollToObjectWithoutHeader} from "@/helpers/scroll";
import KeycloakController from "@/logic/controllers/AuthController";
import {
  formStructureRegisteredOrganization,
  formStructureUnregisteredOrganization
} from './data';

export default class Profile extends Instance {
  activeRequestRefKey: string | null
  CoverProfile: Cover
  FormProfile: FormDx
  FormResetPassword: FormDx
  CoverOrganizations: Cover
  RegisteredOrganizations: Accordion
  UnregisteredOrganizations: Accordion
  CoverOrganization: Cover
  FormOrganization: FormDx
  NotOrganizations: CustomCard
  PopupOrganization: Popup
  PopupTitle: CustomCard
  $l
  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    super(form, Vue, InstancePopup);
    this.$l = useLocale()
    this.activeRequestRefKey = null
    this.CoverProfile = <Cover>form.getComponentByName("CoverProfile")
    this.FormProfile = <FormDx>form.getComponentByName("FormProfile")
    this.FormResetPassword = <FormDx>form.getComponentByName("FormResetPassword")
    this.CoverOrganizations = <Cover>form.getComponentByName("CoverOrganizations")
    this.RegisteredOrganizations = <Accordion>form.getComponentByName("RegisteredOrganizations")
    this.UnregisteredOrganizations = <Accordion>form.getComponentByName("UnregisteredOrganizations")
    this.CoverOrganization = <Cover>form.getComponentByName("CoverOrganization")
    this.FormOrganization = <FormDx>form.getComponentByName("FormOrganization")
    this.NotOrganizations = <CustomCard>form.getComponentByName("NotOrganizations")
    this.PopupOrganization = <Popup>form.getComponentByName("PopupOrganization")
    this.PopupTitle = <CustomCard>form.getComponentByName("PopupTitle")
    this.loadProfile()
    this.loadOrganizations()
    this.emittedEvents()
  }
  loadProfile() {
    this.CoverProfile.startLoad()
    this.FormProfile.setIsReadOnly(true)
    API.profile.getUser(useUser().getUser('id'))
      .then((user1C)=>{
        this.FormProfile.setForm(Object.assign(useUser().getUser(), user1C))
      })
      .catch((e)=>{
        this.setMessage(`${this.$l.message("errors.loadProfile")} ${this.$l.message("errors.serverError.text")}`, 'error')
        console.log("getUser", e)
      })
      .finally(()=>{
        setTimeout(()=>{
            this.CoverProfile.stopLoad()
          },200)
      })
  }
  loadOrganizations() {
    this.CoverOrganizations.startLoad()
    this.NotOrganizations.setContent(this.$l.message("title.notOrganizations")||"")
    API.profile.getCompanies(useUser().getUser('id'))
      .then((companies1C)=>{
        this.NotOrganizations.setIsVisible(!companies1C.length)
        useCounterparties().setCounterparties(companies1C)
        this.RegisteredOrganizations.setAccordion(
          useCounterparties().getCounterparties().filter((counterparty:any)=>counterparty["STATUS"] === "Зарегистрированные организации"),
            this.activeRequestRefKey||useActiveCounterparty().getActiveCounterparty())
        this.UnregisteredOrganizations.setAccordion(
          useCounterparties().getCounterparties().filter((counterparty:any)=>counterparty["STATUS"] === "Незарегистрированные организации"),
            this.activeRequestRefKey||useActiveCounterparty().getActiveCounterparty())
      })
      .catch((e)=>{
        this.setMessage(`${this.$l.message("errors.loadOrganizations")} ${this.$l.message("errors.serverError.text")}`, 'error')
        console.log("getCompanies", e)
      })
      .finally(()=>{
        setTimeout(()=>{
            this.CoverOrganizations.stopLoad()
          },200)
      })
  }
  emittedEvents() {
    this.Vue.bus.on("submitCoverResetPassword", async (form:any) => {
      this.CoverProfile.startLoad()
      form.email = useUser().getUser('email')
      // const keycloak = new KeycloakController
      try {
        // const userToken:any = await keycloak.loginUser(form.email, form.oldPassword)
        // useUser().setUser(Object.assign(useUser().getUser(), userToken))
        try {
          await API.profile.resetPassword({keycloakId: useUser().getUser('id'), password: form?.password})
          this.setMessage( this.$l.message("messages.passwordChangedSuccessfully")||"")
        } catch (e) {
          this.setMessage( this.$l.message("errors.failedToChangePassword")||"", "error")
        }
      } catch (e) {
        this.setMessage( this.$l.message("errors.wrongOldPassword")||"", "error")
      } finally {
        this.CoverProfile.stopLoad()
      }
    })
    this.Vue.bus.on("newOrganization", async (e:any) => {
      this.CoverOrganization.startLoad()
      this.activeRequestRefKey = null
      this.CoverOrganization.fullScreenCover()
      this.FormOrganization.setFormStructure(formStructureUnregisteredOrganization)
      this.FormOrganization.newForm()
      this.FormOrganization.setIsNew(true)
      this.FormOrganization.setIsReadOnly(false)
      setTimeout(()=>{
        this.CoverOrganization.stopLoad()
        scrollToObjectWithoutHeader('.content-body', '#componentCoverOrganization')
      },1000)
    })
    this.Vue.bus.on("updateCoverProfile", () => this.loadProfile())
    this.Vue.bus.on("updateOrganizations", () => this.loadOrganizations())
    this.Vue.bus.on("readRegisteredOrganizations", async (companyId:any) => {
      this.activeRequestRefKey = companyId
      this.CoverOrganization.startLoad()
      const response = useCounterparties().getCounterparties()
        .find((counterparty:any)=>counterparty["COMPANYID"]===companyId)
      if (response){
        this.CoverOrganization.openCover()
        this.FormOrganization.setFormStructure(formStructureRegisteredOrganization)
        this.FormOrganization.setForm(response)
        this.FormOrganization.setIsNew(false)
        this.FormOrganization.setIsReadOnly(true)
      }
      setTimeout(()=> {
        this.CoverOrganization.stopLoad()
        scrollToObjectWithoutHeader('.content-body', '#componentCoverOrganization')
      }, 200)
    })
    this.Vue.bus.on("readUnregisteredOrganizations", async (companyId:any) => {
      this.activeRequestRefKey = companyId
      this.CoverOrganization.startLoad()
      const response = useCounterparties().getCounterparties()
        .find((counterparty:any)=>counterparty["COMPANYID"]===companyId)
      if (response){
        this.CoverOrganization.openCover()
        this.FormOrganization.setFormStructure(formStructureUnregisteredOrganization)
        this.FormOrganization.setForm(response)
        this.FormOrganization.setIsNew(false)
        this.FormOrganization.setIsReadOnly(true)
      }
      setTimeout(()=> {
        this.CoverOrganization.stopLoad()
        scrollToObjectWithoutHeader('.content-body', '#componentCoverOrganization')
      }, 200)
    })
    this.Vue.bus.on("closeCoverOrganization", async (form:any) => {
      try{
        if (this.FormOrganization.getIsUpdateForm()) {
          this.PopupTitle.setContent( this.$l.message("title.areYouSureCloseUnsavedData")||"")
          if (!await this.PopupOrganization.getPopup()) { return }
        }
        this.CoverOrganization.closeCover()
        this.activeRequestRefKey = null
      }catch (e) {}
    })
    this.Vue.bus.on("resizeCoverOrganization", (isFullScreen) => {
      setTimeout(()=>
      scrollToObjectWithoutHeader('.content-body', '#componentCoverOrganization'),300)
    })
    this.Vue.bus.on("submitCoverOrganization", async (form:any) => {
      try{
        this.CoverOrganization.startLoad()
        form['keycloakId'] = useUser().getUser('id')
        if (this.activeRequestRefKey) {
          form['Ref_Key'] = this.activeRequestRefKey
        }
        const response = await API.organizations.submitForm(form)
        this.activeRequestRefKey = response["COMPANYDOKUMENTID"]
        this.setMessage( this.$l.message("messages.submitCoverOrganization")||"")
        this.loadOrganizations()
      } catch (e:any) {
        this.setMessage(`${this.$l.message("errors.submitCoverOrganization")} ${this.$l.message("errors.serverError.text")}`, 'error')
        console.log("getCovers", e)
      } finally {
        this.CoverOrganization.stopLoad()
        this.loadProfile()
      }
    })
  }

  destroy() {}
}
