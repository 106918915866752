import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { PopoverProps } from "./Popover.interface";

export default class Popover extends EPCSComponent {
  declare props:PopoverProps
  constructor(component: IStructureComponentModel) {
    super(component);
    this.props.visible = this.props.visible || false
  }
  getPropVisible():PopoverProps["visible"] { return this.props.visible }
  setPropVisible(value:PopoverProps["visible"]) { this.props.visible = value }
  getTarget():PopoverProps["target"] { return this.props.target }
  setTarget(value:PopoverProps["target"]) { this.props.target = value }
  getPosition():PopoverProps["position"] { return this.props.position }
  setPosition(value:PopoverProps["position"]) { this.props.position = value }
  getTitle():PopoverProps["title"] { return this.props.title }
  setTitle(value:PopoverProps["title"]) { this.props.title = value }
  getContent():PopoverProps["content"] { return this.props.content }
  setContent(value:PopoverProps["content"]) { this.props.content = value }
}
