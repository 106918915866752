import { o } from 'odata';
import config from "@/configs/config"
import {useUser} from "@/store/user";
import type { OdataQuery } from "odata/dist/types/OdataQuery";
const get1C = async function (get: string, query: OdataQuery) {
  return await o(`${config.URL_1C}/odata/standard.odata/`,
    { headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`}
    })
    .get(get)
    .query(query);
}
const getOData = async function (table:string, options?: { filter?: string, top?:number, select?: string, expand?: string, format?:string }) {
  if (!table) { throw "Not table" }
  const url = `${config.URL_1C}/odata/standard.odata/${table}?` +
    `$format=${options?.format ? options.format : 'json'}`+
    `${options?.top ? `&$top=${options.top}` : ''}`+
    `${options?.filter ? `&$filter=${options.filter}` : ''}`+
    `${options?.select ? `&$select=${options.select}` : ''}`+
    `${options?.expand ? `&$expand=${options.expand}` : ''}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": "application/json",
    }
  })
  if (!data.ok) { throw await data.json() }
  const result = await data.json()
  return result.value
}
import ferryTransportation from "./ferryTransportation"
import inquiries from "./inquiries"
import calculator from "./calculator"
import profile from "./profile"
import organizations from "./organizations"
import carrier from "./carrier"
import getFile from "./getFile"
import maps from "./maps"
export default {
  get1C, getOData,
  ferryTransportation: {...ferryTransportation},
  inquiries: {...inquiries},
  calculator: {...calculator},
  profile: {...profile},
  organizations: {...organizations},
  carrier: {...carrier},
  getFile: {...getFile},
  maps: {...maps},
};
