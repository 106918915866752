import config from "@/configs/config";
import {useUser} from "@/store/user";

async function getInquiries (NodeCode:string, keycloak:string) {
  if (!NodeCode) { throw "no counterparty" }
  const url = `${config.URL_1C}/hs/LK_Qw/ListOfOrders?NodeCode=${NodeCode}&keycloak=${keycloak}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": "application/json",
    }
  })
  if (!data.ok) { throw await data.json() }
  const allInquiries = await data.json()
  return allInquiries[Object.keys(allInquiries)[0]]
}
async function getArrivalDate (localityKey:string, paidRoute:string) {
  if (!localityKey && !paidRoute) { throw "no counterparty" }
  const url = `${config.URL_1C}/hs/LK_Schedule/Calculate/ArrivalDate?Пункт_Key=${localityKey}&Маршрут_Key=${paidRoute}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": "application/json",
    }
  })
  if (!data.ok) { throw await data.json() }
  const result = await data.json()
  return result[Object.keys(result)[0]]
}
async function getListOfViewedInquiries (keycloak:string) {
  if (!keycloak) { throw "no counterparty" }
  const url = `${config.URL_1C}/hs/LK_Schedule/ListOfViewedApplications/Request?keycloakId=${keycloak}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": "application/json",
    }
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
export interface formGetAgreedRate {
  Client_ID: string
  Contract_ID: string
  Route_ID: string
  StationFrom_ID: string
  StationTo_ID: string
  ETSNG_ID: Array<string>
  Date: Date,
  CarriageLength: number,
}
async function getAgreedRate (form:formGetAgreedRate) {
  if (Object.values(form).filter((value:any)=> value === null || value === undefined).length) { throw "no all data" }
  const url = `${config.URL_1C}/hs/LK_Schedule/Calculate/Rate`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
export default {
  getInquiries,
  getArrivalDate,
  getListOfViewedInquiries,
  getAgreedRate
}
