export const localesList = [
  {
    name: 'Русский',
    value: 'ru',
  },
  {
    name: 'English',
    value: 'en',
  },
  {
    name: '中国人',
    value: 'zh',
  },
]
// @ts-ignore
import ru from "@/locales/ru.json"
// @ts-ignore
import en from "@/locales/en.json"
// @ts-ignore
import zh from "@/locales/zh.json"
export const dictionary = {ru, en, zh}
