export const scrollToObjectWithoutHeader = (contentBody: string, element:string, behavior:ScrollBehavior = 'smooth') => {
  const queryElement = document?.querySelector<HTMLElement>(element)
  const contentBodyElement = document.querySelector<HTMLElement>(contentBody)
  if (queryElement != null && contentBodyElement != null) {
    const header = document.querySelector<HTMLElement>('#header')
    const navigation = document.querySelector<HTMLElement>('.vertical-navigation')
    if (queryElement?.offsetTop && header?.scrollHeight && queryElement?.offsetLeft && navigation?.scrollWidth) {
      contentBodyElement.scroll({
        top: queryElement?.offsetTop/* - header?.scrollHeight*/,
        // left: queryElement.offsetLeft + queryElement.clientWidth - navigation?.clientWidth,
        behavior
      })
    }
  }
}
export const scrollToBottom = (toElement:string, behavior?:'smooth') => {
  const element = document.querySelector<HTMLElement>(toElement)
  if (element) {
    element.scrollTo({top: element?.scrollHeight, behavior:"smooth"})
  }
}
