import config from "@/configs/config";
import {useUser} from "@/store/user";

async function getFile (urlFile:any) {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const url = `${config.URL_1C}/hs/LK_Schedule/${urlFile}`
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Authorization", `Bearer ${useUser().getUser('access_token')}`);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.responseType = 'blob';
    xhr.onload = function() {
      if (xhr.status === 200){
        const file = new Blob([xhr.response], {type: 'application/pdf'});
        resolve(URL.createObjectURL(file))
      } else { reject(JSON.parse(xhr.response)) }
    }
    const isValid = function() { if (xhr.status >= 300){ reject(xhr.response) } }
    xhr.onerror = isValid
    xhr.onloadstart = isValid
    xhr.onloadend = isValid
    xhr.send();
  })
}
export default {
  getFile,
}