import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { DataGridProps, GridColumns } from "./DataGrid.interface";
import { setColumns } from "@/helpers/toObject";
import type dxDataGrid from "devextreme/ui/data_grid";

export default class DataGrid extends EPCSComponent {
  declare props:DataGridProps
  isVisible: boolean
  focusedRowKey: string | null
  baseGridColumns:Array<GridColumns>
  dataGridInstance: dxDataGrid|null
  constructor(component: IStructureComponentModel) {
    super(component);
    this.isVisible = true
    this.focusedRowKey = null
    this.baseGridColumns = setColumns(this.props.gridColumns)
    this.dataGridInstance = null
  }
  protected setDataInstance(data:dxDataGrid){ this.dataGridInstance = data }
  getDataInstance():dxDataGrid|null{
    return this.dataGridInstance
  }
  getColumns ():DataGridProps["gridColumns"] { return this.baseGridColumns }
  setColumns (value:DataGridProps["gridColumns"]) { this.baseGridColumns = value }
  getFilters ():DataGridProps["filters"] { return this.props.filters }
  setFilters (value:DataGridProps["filters"]) { this.props.filters = value }
  setDataSource(data: DataGridProps["dataSource"]|[]) {
    this.props.dataSource = data;
  }
  startLoad(): void {
    this.props.isLoaded = true;
  }
  stopLoad(): void {
    this.props.isLoaded = false;
  }
  getIsVisible () {
    return this.isVisible
  }
  setIsVisible (value:boolean) {
    this.isVisible = value
  }
  getFocusedRowKey () {
    return this.focusedRowKey
  }
  setFocusedRowKey (value:string|null) {
    if (this.props.dataSource){
      this.props.dataSource = <DataGridProps["dataSource"]|[]>this.props.dataSource.map(data=>{
        if (this.props.valueKey && this.props.isViewedRowField && data[this.props.valueKey] === value) {
          data[this.props.isViewedRowField] = true
        }
        return data
      })
    }
    this.focusedRowKey = value
  }
}
