import EPCSComponentsFactory from "@epcs/sitronics-spa/components/EPCSComponentsFactory";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
// Тут мы импортируем наши кастомные компоненты.
import type { EPCSComponentsClass } from "@epcs/sitronics-spa/components/componentsList";
import CustomCard from "@/static/components/CustomCard/CustomCard";
import Popup from "@/static/components/Popup/Popup";
import Popover from "@/static/components/Popover/Popover";
import DataGrid from "@/static/components/DataGrid/DataGrid";
import Cover from "@/static/components/Cover/Cover";
import Window from "@/static/components/Window/Window";
import FormDx from "@/static/components/Form/Form";
import FormHistory from "@/static/components/FormHistory/FormHistory";
import Accordion from "@/static/components/Accordion/Accordion";
import TileView from "@/static/components/TileView/TileView";
import TreeView from "@/static/components/TreeView/TreeView";
import FilePreview from "@/static/components/FilePreview/FilePreview";
import Weather from "@/static/components/Weather/Weather";

export default class EPCSComponentsFactoryCustom extends EPCSComponentsFactory {
  component: IStructureComponentModel;

  constructor(component: IStructureComponentModel) {
    super(component);
    this.component = component;
  }

  createCustomComponent(): EPCSComponentsClass {
    switch (String(this.component.component.name)) {
      case "CustomCard": {
        return new CustomCard(this.component);
      }
      case "Popup": {
        return new Popup(this.component);
      }
      case "Popover": {
        return new Popover(this.component);
      }
      case "DataGrid": {
        return new DataGrid(this.component);
      }
      case "Cover": {
        return new Cover(this.component);
      }
      case "Window": {
        return new Window(this.component);
      }
      case "FormDx": {
        return new FormDx(this.component);
      }
      case "FormHistory": {
        return new FormHistory(this.component);
      }
      case "Accordion": {
        return new Accordion(this.component);
      }
      case "TileView": {
        return new TileView(this.component);
      }
      case "TreeView": {
        return new TreeView(this.component);
      }
      case "FilePreview": {
        return new FilePreview(this.component);
      }
      case "Weather": {
        return new Weather(this.component);
      }
      default: {
        throw new Error(
          `Компонент отсутствует в цепочке switch/case Кастомной фабрики компонентов.`
        );
      }
    }
  }
}
