import {defineStore} from "pinia";
import {ref} from "vue";

export const useUser = defineStore('user', () => {
  // @ts-ignore
  let user:any = ref(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :'')
  function getUser (field?: string){
    if (field){
      return user.value[field]
    } else {
      return user.value
    }
  }
  function setUser (value?: string|object, field?: string):void{
    if (value) {
      if (field){
        user.value[field] = value
      } else {
        user.value = value
      }
      // @ts-ignore
      localStorage.setItem(this.$id, JSON.stringify(user.value));
    } else {
      // @ts-ignore
      localStorage.removeItem(this.$id)
    }
  }
  return { user, getUser, setUser }
})
