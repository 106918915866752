import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { TreeViewProps } from "./TreeView.interface";

export default class TreeView extends EPCSComponent {
  declare props:TreeViewProps
  constructor(component: IStructureComponentModel) {
    super(component);
  }
  getStyle ():TreeViewProps["style"] { return this.props.style }
  setStyle (value:TreeViewProps["style"]) { this.props.style = value }
  getTreeStructure():TreeViewProps["treeStructure"] { return this.props.treeStructure }
  setTreeStructure(value:TreeViewProps["treeStructure"]) { this.props.treeStructure = value }
}
