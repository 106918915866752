import type { FormStructure, FieldsForm } from "@/static/components/Form/Form.interface";
import type { GridColumns } from "@/static/components/DataGrid/DataGrid.interface";
export const gridColumnsFerryRequest: Array<GridColumns> = [
  {dataField: "Date", caption: "nameColumn.dateOfCreation", dataType: "date", sortOrder:"desc", width: 150, alignment:"left"},
  {dataField: "Cargos", caption: "nameColumn.cargo", dataType: "string", alignment:"left"},
  {dataField: "departurePoint", caption: "nameColumn.departureStation", dataType: "string", alignment:"left"},
  {dataField: "destinationPoint", caption: "nameColumn.destinationStation", dataType: "string", alignment:"left"},
  {dataField: "status", caption: "nameColumn.status", dataType: "string", alignment:"left"},
]
export const filtersFerryRequest = [
  // {
  //   items: [{ name: "Все", key: "Все"}, { name: "Новые", key: false }, { name: "Просмотренные", key: true }],
  //   filterableField: "isViewed",
  //   width: 170
  // },
]
export const formStructureFerryRequest: FormStructure = [
  {
    titleGroup: "form.title.basicInformation",
    fields: ["departurePoint", "destinationPoint", "preKerridge"],
    groups: [ { dependentField: "preKerridge", fields: ["cargoPickupAddress"] } ]
  },
  {
    titleGroup: "form.title.cargo",
    fields: ["cargoArray"],
  },
  {
    titleGroup: "form.title.transportationPeriod",
    fields: ["departureDate"]
  },
  { titleGroup: "form.title.shipper",
    fields: ["shipper"],
  },
  { titleGroup: "form.title.consignee",
    fields: ["consignee"],
  },
  {
    titleGroup: "form.title.contract",
    fields: ["contract" ]
  },
]
export const fieldsFormFerryRequest: FieldsForm = {
  departurePoint: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_Линии",
    value: null,
    valueKey: "ПунктОтправки.Ref_Key",
    notDeletionMark: true,
    label: "form.label.departurePoint",
    showClearButton:true,
    rules: ["required"],
    filter: [
      // ["ПунктНазначения.Ref_Key", "=", "destinationPoint"],
      // "and",
      [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true],
    ],
    gridColumns: [
      {dataField: "ПунктОтправки.Description", caption: "nameColumn.station", dataType: "string", expand: "ПунктНазначения"},
      {dataField: "ПунктОтправки.Страна.Description", caption: "nameColumn.country", dataType: "string", expand: "ПунктОтправки/Страна"}
    ],
    distinct: true
    // nameField: "МассивЭлементов[0].СтанцияОтправления"
  },
  destinationPoint: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_Линии",
    value: null,
    valueKey: "ПунктНазначения.Ref_Key",
    notDeletionMark: true,
    label: "form.label.destinationPoint",
    showClearButton:true,
    rules: ["required"],
    filter: [
      // ["ПунктОтправки.Ref_Key", "=", "departurePoint"],
      // "and",
      [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true],
    ],
    gridColumns: [
      {dataField: "ПунктНазначения.Description", caption: "nameColumn.station", dataType: "string", expand: "ПунктОтправки"},
      {dataField: "ПунктНазначения.Страна.Description", caption: "nameColumn.country", dataType: "string", expand: "ПунктНазначения/Страна"}
    ],
    distinct: true
  },
  //----------
  preKerridge: {
    typeDx: "dxCheckBox",
    value: false,
    text: "form.text.preKerridge",
  },
  cargoPickupAddress: {
    typeDx: "dxTextArea",
    value: null,
    label: "form.label.cargoPickupAddress",
    maxLength: 250
  },
  //----------
  cargoArray: {
    typeDx: "dxMultipleInput",
    value: null,
    label: "form.label.cargos",
    gridColumns: [
      {dataField: "cargoName", caption: "nameColumn.name", dataType: "string", rules: ["required"], minWidth: 100, editorOptions: { placeholder: "special.search" }},
      {dataField: "cargoNameTNVED", caption: "nameColumn.cargoNameAndCodeForTNVED", rules: ["required"], dataType: "string", alignment: "left", width: 300, minWidth: 100, editorOptions: { placeholder: "special.search" },
        allowFiltering: false,
        cellTemplate: "dropDownBoxCellTemplate",
        editCellTemplate: "dropDownBoxEditor",
        optionsDownBoxEditor: {
          typeDx: "dxDropDownBox",
          nameTable: "Catalog_КлассификаторГрузовТНВЭД",
          value: null,
          labelMode: "hidden",
          showClearButton: true,
          gridColumns: [
            {dataField: "Description", caption: "nameColumn.shippingName", dataType: "string"},
            {dataField: "Code", caption: "nameColumn.cargoCode", dataType: "string"}
          ],
        },
      },
      {dataField: "cargoWeight", caption: "nameColumn.cargoWeight", dataType: "number", alignment:"left", minWidth: 80, editorOptions: { placeholder: "special.search" }},
      {dataField: "unit", caption: "nameColumn.unit", dataType: "string", alignment: "left", width: 80, minWidth: 80, editorOptions: { placeholder: "special.search" },
        cellTemplate: "dropDownBoxCellTemplate",
        editCellTemplate: "dropDownBoxEditor",
        optionsDownBoxEditor: {
          typeDx: "dxDropDownBox",
          nameTable: "Catalog_ЕдиницыИзмерения",
          value: null,
          labelMode: "hidden",
          showClearButton: true,
          filter: [
            [ "Description", "=", "кг" ],
            "or",
            [ "Description", "=", "т" ],
          ],
          showColumnHeaders: false,
          gridColumns: [ {dataField: "Description", allowFiltering: false, allowSorting: false, allowHeaderFiltering: false,caption: "nameColumn.view", dataType: "string"} ],
        },
      },
      {dataField: "volume", caption: "nameColumn.volume", dataType: "number", alignment:"left", minWidth: 100, editorOptions: { placeholder: "special.search" }},
      {dataField: "quantityPosition", caption: "nameColumn.quantity", dataType: "number", rules: ["required"], alignment:"left", minWidth: 100, editorOptions: { placeholder: "special.search" }},
    ],
    calculateCustomSummary(options, table) {
      if (options.summaryProcess === "calculate" && options.name == "cargoWeight") {
        options.totalValue = (table.getDataSource().store() as any)?._array.reduce((sum:number, item:any)=>sum+item["quantityPosition"]*item["cargoWeight"], 0)
      }
      if (options.summaryProcess === "calculate" && options.name == "volume") {
        options.totalValue = (table.getDataSource().store() as any)?._array.reduce((sum:number, item:any)=>sum+item["quantityPosition"]*item["volume"], 0)
      }
    },
    summaryTotalItems: [
      {column: "cargoName", summaryType: "count", displayFormat: "form.summaryTotalItems.cargo"},
      {column: "cargoWeight", name: "cargoWeight", summaryType: "custom", displayFormat: "form.summaryTotalItems.cargoWeight"},
      {column: "volume", name: "volume", summaryType: "custom", displayFormat: "form.summaryTotalItems.volume"},
      {column: "quantityPosition", summaryType: "sum", displayFormat: "form.summaryTotalItems.count"}
    ],
    rules: ["required"],
    textNoData: "form.textNoData.noData",
    nameField: "CargoArray"
  },
  //----------
  departureDate: {
    typeDx: "dxDateBox",
    value: null,
    label: "form.label.with",
    printLabel: "form.printLabel.startOfTransportation",
    rules: ["required"],
    moreFields: [],
    lessFields: ["arrivalDate"],
  },
  //----------
  shipper: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_ГрузоотправителиГрузополучателиЛКК",
    newEvent: "createNewShipper",
    value: null,
    label: "form.label.shipperName",
    showClearButton:true,
    filter: [ "ЭтоГрузоотправитель", "=", true],
    rules: ["required"],
    gridColumns: [
      {name: "name", dataField: "Description", caption: "nameColumn.name", dataType: "string", editorOptions: { placeholder: "special.search" }},
      {name: "phone", dataField: "КонтактнаяИнформация", caption: "nameColumn.phone", dataType: "string", editorOptions: { placeholder: "special.search" },
        calculateCellValue: function(rowData) {
          if (rowData["КонтактнаяИнформация"]?.length){
            const resultItem = rowData["КонтактнаяИнформация"]?.find((item:any)=>item["Тип"] === "Телефон")
            return resultItem ? resultItem["Представление"] : ""
          } else { return "" }
        }
      },
      {name: "email", dataField: "КонтактнаяИнформация", caption: "nameColumn.email", dataType: "string", editorOptions: { placeholder: "special.search" },
        calculateCellValue: function(rowData) {
          if (rowData["КонтактнаяИнформация"]?.length){
            const resultItem = rowData["КонтактнаяИнформация"]?.find((item:any)=>item["Тип"] === "АдресЭлектроннойПочты")
            return resultItem ? resultItem["Представление"] : ""
          } else { return "" }
        }
      },
      {name: "info", dataField: "КонтактнаяИнформация", caption: "nameColumn.info", dataType: "string", editorOptions: { placeholder: "special.search" },
        calculateCellValue: function(rowData) {
          if (rowData["КонтактнаяИнформация"]?.length){
            const resultItem = rowData["КонтактнаяИнформация"]?.find((item:any)=>item["Тип"] === "Другое")
            return resultItem ? resultItem["Представление"] : ""
          } else { return "" }
        }
      }
    ],
  },
  consignee: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_ГрузоотправителиГрузополучателиЛКК",
    newEvent: "createNewConsignee",
    value: null,
    label: "form.label.nameConsignee",
    showClearButton:true,
    filter: [ "ЭтоГрузополучатель", "=", true],
    rules: ["required"],
    gridColumns: [
      {name: "name", dataField: "Description", caption: "nameColumn.name", dataType: "string", editorOptions: { placeholder: "special.search" }},
    ],
  },
  //----------
  contract: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_ДоговорыКонтрагентов",
    value: null,
    label: "form.label.numberAndDateOfTheContract",
    rules: ["required"],
    filter: [
      [ "Owner_Key", "=", "customParameter" ],
      "and",
      [ "Организация_Key", "=", "e4fe2e46-6724-11ed-8196-005056018264"]
    ],
    gridColumns: [
      {dataField: "Номер", caption: "nameColumn.contractNumber", dataType: "string"},
      {dataField: "Дата", caption: "nameColumn.agreementDate", dataType: "date", format: { type:"shortDate" } }
    ],
  },
  //----------
}
export const formStructurePopupUnpublished: FormStructure = [{ titleGroup: "", fields: ["comment"] }]
export const fieldsFormPopupUnpublished: FieldsForm = {
  comment: {
    typeDx: "dxTextArea",
    value: null,
    label: "form.label.reasonForCancellation",
    rules: ["required"],
    height:"14rem"
  }
}
export const formStructurePopupNewShipper: FormStructure = [{titleGroup: "", fields: ["Name", "Phone", "Email", "Info"]}]
export const fieldsFormPopupNewShipper: FieldsForm = {
  Name: {
    typeDx: "dxTextBox",
    value: null,
    label: "form.label.nameCarrier",
    rules: ["required"]
  },
  Phone: {
    typeDx: "dxTextBox",
    value: null,
    mode: "phone",
    label: "form.label.phone",
    labelMode: "static",
    rules: ["required"]
  },
  Email: {
    typeDx: "dxTextBox",
    value: null,
    mode: "email",
    label: "form.label.email",
  },
  Info:{
    typeDx: "dxTextArea",
    value: null,
    label: "form.label.comment",
    maxLength: 250
  }
}
export const formStructurePopupNewConsignee: FormStructure = [{ titleGroup: "", fields: ["Name"] }]
export const fieldsFormPopupNewConsignee: FieldsForm = {
  Name: {
    typeDx: "dxTextBox",
    value: null,
    label: "form.label.nameCarrier",
    rules: ["required"]
  },
}