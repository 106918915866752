<template>
  <Toolbar v-if="!noToolbar" data="">
    <FormTemplate
      :componentsVue="components"
      :key="$route.name"
    ></FormTemplate>
  </Toolbar>
  <template v-else>
    <FormTemplate
      :componentsVue="components"
      :key="$route.name"
    ></FormTemplate>
  </template>
</template>

<script setup lang="ts">
import FormTemplate from "../components/FormTemplate.vue";

import {
  defineProps,
  onMounted,
  nextTick,
  inject,
  ref,
  defineAsyncComponent,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import type Instance from "@/logic/views/instance/instance";
import type {IEPCSPopup} from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import logicMap from "@/logicMap";
import type Keycloak from "keycloak-js";

const $route = useRoute();
const router = useRouter();
const bus:any = inject("bus");
const keycloak = <Keycloak>inject("keycloak");

let logicTemplate: Instance | null = null;
let instancePopup = ref<IEPCSPopup>();

const noToolbar = ref(false);
const Toolbar = defineAsyncComponent(() => import("../static/composition/Toolbar/Toolbar.vue"));

const props = defineProps(["components", "form", "metaData", "application"]);

onMounted(() => {
  noToolbar.value = !!$route.meta["noToolbar"];
  refresh();
});

watch(
  () => $route.name,
  () => {
    refresh();
  }
);

const refresh = () => {
  router.isReady().then(() => {
    nextTick(() => {
      if (logicTemplate) {
        logicTemplate.destroy();
      }
      
      const logicName:string = String($route.meta["logicName"])
      if (instancePopup && logicName) {
        logicTemplate = logicMap[logicName]
          ? new logicMap[logicName](
            props.form,
            {
              bus,
              router,
              keycloak: keycloak,
              currentRouter: $route,
              metaData: props.metaData || {},
            },
            <IEPCSPopup>instancePopup.value
          )
          : null;
      }
      noToolbar.value = !!$route.meta["noToolbar"];
    });
  });
};

</script>
