import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {TileViewProps} from "./TileView.interface";

export default class TileView extends EPCSComponent {
  declare props:TileViewProps
  constructor(component: IStructureComponentModel) {
    super(component);
  }
  getIsLoaded():TileViewProps["isLoaded"] { return this.props.isLoaded }
  setIsLoaded(value:TileViewProps["isLoaded"]) { this.props.isLoaded = value; }
  getTileViewStructure():TileViewProps["tileViewStructure"] { return this.props.tileViewStructure }
  setTileViewStructure(value:TileViewProps["tileViewStructure"]) { this.props.tileViewStructure = value; }
}
