import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {FilePreviewProps} from "./FilePreview.interface";

export default class FilePreview extends EPCSComponent {
  declare props:FilePreviewProps
  constructor(component: IStructureComponentModel) {
    super(component);
  }
  getFilePreviewStructure():FilePreviewProps["listFiles"] {
    return this.props.listFiles
  }
  setFilePreviewStructure(value:FilePreviewProps["listFiles"]) {
    this.props.listFiles = value;
  }
}
