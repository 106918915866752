import config from "@/configs/config";
import {useUser} from "@/store/user";

async function submitForm (form:any) {
  const url = `${config.URL_1C}/hs/LK_Qw/CreateOrder`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function getCover (NodeCode:string) {
  const url = `${config.URL_1C}/hs/LK_Qw/GetOrderByID?keycloakId=${useUser().getUser('id')}&NodeCode=${NodeCode}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    },
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function getODataOnRefKey (nameTable:string, refKey: string) {
  if (!refKey) { return refKey }
  const url = `${config.URL_1C}/odata/standard.odata/${nameTable}?$format=json&$filter=Ref_Key eq guid'${refKey}'`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    },
  })
  if (!data.ok) { throw await data.json() }
  const request = await data.json()
  return request.value?.[0] ? request.value[0] : null
}
async function setStatus (NodeCode:string) {
  const url = `${config.URL_1C}/hs/LK_Qw/Approval?NodeCode=${NodeCode}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    },
  })
  if (!data.ok) { throw await data.json() }
  return await data.json()
}
async function deleteRequest (NodeCode:string) {
  const url = `${config.URL_1C}/hs/LK_Qw/DeleteApplication?NodeCode=${NodeCode}`
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    },
  })
  if (!data.ok) { throw await data.json() }
  const response = await data.json()
  return response[Object.keys(response)[0]]
}
async function withdrawnApplication (form:any) {
  const url = `${config.URL_1C}/hs/LK_Qw/WithdrawnApplication`
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${useUser().getUser('access_token')}`,
      "Content-Type": 'application/json',
    },
    body: JSON.stringify(form)
  })
  if (!data.ok) { throw await data.json() }
  const response = await data.json()
  return response[Object.keys(response)[0]]
}
export default {
  submitForm, getCover, getODataOnRefKey, setStatus, deleteRequest, withdrawnApplication
}
