import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { PopupProps } from "./Popup.interface";
import {ref, watch} from "vue";

const popupResult = ref<boolean|null>(null)

export default class Popup extends EPCSComponent {
  declare props:PopupProps
  valueTextArea?: string|null
  constructor(component: IStructureComponentModel) {
    super(component)
    this.valueTextArea = null
  }
  async getPopup() {
    this.props.isVisible = true
    const result = await this.getWarningDialog();
    this.props.isVisible = false
    return result
  }
  getWarningDialog(): Promise<boolean>{
    popupResult.value = null
    return new Promise((resolve) => {
      watch(popupResult, (value) => {
        if (value !== null) { resolve(value) }
      })
    })
  }
  getPopupTitle ():PopupProps["title"] { return this.props.title }
  setPopupTitle (value:PopupProps["title"]) { this.props.title = value }
  getButtonConfirm ():PopupProps["buttonConfirm"] { return this.props.buttonConfirm }
  setButtonConfirm (value:PopupProps["buttonConfirm"]) { this.props.buttonConfirm = value }
  getButtonReject ():PopupProps["buttonReject"] { return this.props.buttonReject }
  setButtonReject (value:PopupProps["buttonReject"]) { this.props.buttonReject = value }
  getIsVisibleButtons ():PopupProps["isVisibleButtons"] { return this.props.isVisibleButtons }
  setIsVisibleButtons (value:PopupProps["isVisibleButtons"]) { this.props.isVisibleButtons = value }
 getIsHideOnOutsideClick ():PopupProps["isHideOnOutsideClick"] { return this.props.isHideOnOutsideClick }
  setIsHideOnOutsideClick (value:PopupProps["isHideOnOutsideClick"]) { this.props.isHideOnOutsideClick = value }
  getStyle ():PopupProps["style"] { return this.props.style }
  setStyle (value:PopupProps["style"]) { this.props.style = value }
  getPopupResult () {
    return popupResult.value
  }
  setPopupResult (value:null|boolean) {
    popupResult.value = value
  }
}
