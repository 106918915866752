import type { IStructureModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import { StructuresModelType } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { CoverModel } from "@/static/components/Cover/Cover.interface";
import type { DataGridModel } from "@/static/components/DataGrid/DataGrid.interface";
import type { FormModel } from "@/static/components/Form/Form.interface";
import type { PopupModel } from "@/static/components/Popup/Popup.interface";
import type {CustomCardModel} from "@/static/components/CustomCard/CustomCard.interface";

import {formStructureFerryRequest, fieldsFormFerryRequest, gridColumnsFerryRequest, filtersFerryRequest} from './data';

const Inquiries: IStructureModel = {
  structureType: StructuresModelType.sform,
  components: [
    {
      priority: 0,
      render: true,
      componentName: "CoverListInquiries",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          title: "title.ferryCrossings",
          style: { justifyContent: "space-between" },
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true,
          newEvent: "newCover",
          newText: "btn.createRequest",
          updateEvent: "updateTable",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "DataGrid",
      parent: "CoverListInquiries",
      col: 12,
      component: <DataGridModel>{
        name: "DataGrid",
        props: {
          valueKey: 'ID',
          gridColumns: gridColumnsFerryRequest,
          // filters: filtersFerryRequest,
          onRowClickEvent: "openCover",
          // isViewedRowField: "isViewed",
          style: {
            height: "79vh"
          },
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "Cover",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          title: "title.ferryCrossing",
          isExists: false,
          isTextButton: false,
          closeEvent: "closeCover",
          updateEvent: "updateCover",
          // duplicateEvent: "duplicateCover",
          resizeEvent: "resizeCover",
          // printEvent: "printCover"
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "Form",
      parent: "Cover",
      col: 12,
      component: <FormModel>{
        name: "FormDx",
        props: {
          formStructure: formStructureFerryRequest,
          fieldsForm: fieldsFormFerryRequest,
          // updateFieldEvent: "updateFieldEvent",
          // readEvent: "unpublishedFerryRequest",
          // readText: "btn.cancelRequest",
          // deleteEvent: "deleteFerryRequest",
          // deleteText: "btn.delete",
          // submitEvent: "submitFerryRequest",
          // submitText: "btn.save",
          validateSubmitEvent: "submitFerryRequest",
          validateSubmitText: "btn.sendForApproval",
        },
      },
    },
    {
      priority: 1,
      render: true,
      componentName: "NotInquiries",
      parent: "",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          isVisibleCustomCard: false,
          type: "noData",
          style: {
            padding: "5rem",
            backgroundColor: "var(--zircon)",
            boxShadow: "0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)",
            margin: "1rem",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            textAlign: "center",
            zIndex: 10,
            borderRadius: "5px"
          },
          url: "icon/directions_boat_white.svg",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "PopupRequest",
      parent: "",
      col: 12,
      component: <PopupModel>{
        name: "Popup",
        props: {}
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "PopupTitle",
      parent: "PopupRequest",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          type: "p",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "PopupForm",
      parent: "PopupRequest",
      col: 12,
      component: <FormModel>{
        name: "FormDx",
        props: {},
      },
    },
  ],
};
export { Inquiries };
