import config from "@/configs/config"
export default class KeycloakController {
  // loginUser (login:string, password: string) {
  //   let xhr = new XMLHttpRequest();
  //   return new Promise((resolve, reject) => {
  //     const url = `${config.URL_KEYCLOAK}/realms/${config.REALM_KEYCLOAK}/protocol/openid-connect/token`
  //     let body = 'grant_type=' + encodeURIComponent("password") +
  //       '&client_id=' + encodeURIComponent(config.CLIENT_KEYCLOAK) +
  //       '&client_secret=' + encodeURIComponent(config.CLIENT_SECRET_KEYCLOAK) +
  //       '&username=' + encodeURIComponent(login) +
  //       '&password=' + encodeURIComponent(password);
  //     xhr.open("POST", url, true);
  //     xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //     xhr.send(body);
  //     xhr.onload = function() {
  //       if (xhr.status ===200){
  //         resolve(JSON.parse(xhr.response))
  //       } else {
  //         reject(JSON.parse(xhr.response)) }
  //     }
  //     xhr.onerror = function() {
  //       reject(xhr.response)
  //     }
  //   })
  // }
  async setUser(access_token: string, form: any) {
    const url = `${config.URL_KEYCLOAK}/realms/${config.REALM_KEYCLOAK}/account/`
    return await fetch(url, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${access_token}`,
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(form)
    })
  }
  async getUser(access_token: string) {
    const url = `${config.URL_KEYCLOAK}/realms/${config.REALM_KEYCLOAK}/account/`
    const data = await fetch(url, {
      headers: {
        "Authorization": `Bearer ${access_token}`,
        "Content-Type": 'application/json'
      }
    })
    return await data.json()
  }
  registrationUser(access_token: string, form: any) {
    let xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      const url = `${config.URL_KEYCLOAK}/admin/realms/${config.REALM_KEYCLOAK}/users`
      xhr.open("POST", url, true);
      xhr.setRequestHeader('Authorization', `bearer ${access_token}`);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(form));
      xhr.onload = function() {
        if (xhr.status === 201){
          let userId = xhr.getResponseHeader("Location")
          if (userId) {
            userId = userId.split("/")[userId.split("/").length -1]
          }
          resolve(userId)
        } else { reject(JSON.parse(xhr.response)) }
      }
      xhr.onerror = function() {
        reject(xhr.response)
      }
    })
  }
  resetPassword(access_token: string, userId: string, password: string) {
    let xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      const url = `${config.URL_KEYCLOAK}/admin/realms/${config.REALM_KEYCLOAK}/users/${userId}/reset-password`
      const body = {
        "temporary": false,
        "type": "password",
        "value": password
      }
      xhr.open("PUT", url, true);
      xhr.setRequestHeader('Authorization', `bearer ${access_token}`);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onload = function() {
        if (xhr.status < 300){
          resolve(userId)
        } else { reject(JSON.parse(xhr.response)) }
      }
      xhr.onerror = function() {
        reject(xhr.response)
      }
    })
  }
  // updateRefreshToken(refresh_token: string) {
  //   let xhr = new XMLHttpRequest();
  //   return new Promise((resolve, reject) => {
  //     const url = `${config.URL_KEYCLOAK}/realms/${config.REALM_KEYCLOAK}/protocol/openid-connect/token`
  //     let body =
  //       'client_id=' + encodeURIComponent(config.CLIENT_KEYCLOAK) +
  //       '&client_secret=' + encodeURIComponent(config.CLIENT_SECRET_KEYCLOAK) +
  //       '&grant_type=' + encodeURIComponent("refresh_token") +
  //       '&refresh_token=' + encodeURIComponent(refresh_token);
  //     xhr.open("POST", url, true);
  //     xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //     xhr.send(body);
  //     xhr.onload = function() {
  //       if (xhr.status ===200){
  //         resolve(JSON.parse(xhr.response))
  //       } else { reject(JSON.parse(xhr.response)) }
  //     };
  //     xhr.onerror = function() {
  //       reject(xhr.response)
  //     }
  //   })
  // }
  // async logoutUser(access_token:string, refresh_token: string) {
  //   let xhr = new XMLHttpRequest();
  //   return new Promise((resolve, reject) => {
  //     const url = `${config.URL_KEYCLOAK}/realms/${config.REALM_KEYCLOAK}/protocol/openid-connect/logout`
  //     let body =
  //       'client_id=' + encodeURIComponent(config.CLIENT_KEYCLOAK) +
  //       '&client_secret=' + encodeURIComponent(config.CLIENT_SECRET_KEYCLOAK) +
  //       '&refresh_token=' + encodeURIComponent(refresh_token);
  //     xhr.open("POST", url, true);
  //     xhr.setRequestHeader('Authorization', `Bearer ${refresh_token}`);
  //     xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //     xhr.send(body);
  //     xhr.onload = function() { resolve(xhr.response) }
  //     xhr.onerror = function() { reject(xhr.response) }
  //   })
  // }
  // deleteUser (access_token: string, userId: string) {
  //   let xhr = new XMLHttpRequest();
  //   return new Promise((resolve, reject) => {
  //     const url = `${config.URL_KEYCLOAK}/admin/realms/${config.REALM_KEYCLOAK}/users/${userId}`
  //     xhr.open("DELETE", url, true);
  //     xhr.setRequestHeader('Authorization', `bearer ${access_token}`);
  //     xhr.setRequestHeader('Content-Type', 'application/json');
  //     xhr.onload = function() {
  //       if (xhr.status < 300){
  //         resolve(true)
  //       } else { reject(false) }
  //     }
  //     xhr.onerror = function() {
  //       reject(xhr.response)
  //     }
  //   })
  // }
}
