import type { IStructureModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import { StructuresModelType } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { CoverModel } from "@/static/components/Cover/Cover.interface";
import type { DataGridModel } from "@/static/components/DataGrid/DataGrid.interface";

import { filtersScoreRequest, gridColumnsScoreRequest } from './data';

const Score: IStructureModel = {
  structureType: StructuresModelType.sform,
  components: [
    {
      priority: 0,
      render: true,
      componentName: "CoverScore",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          style: { justifyContent: "space-between" },
          title: "title.scores",
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true,
          updateEvent: "updateCover",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "DataGrid",
      parent: "CoverScore",
      col: 12,
      component: <DataGridModel>{
        name: "DataGrid",
        props: {
          valueKey: 'Ref_Key',
          // nameTable: 'Document_СчетНаОплатуПокупателю',
          gridColumns: gridColumnsScoreRequest,
          filters: filtersScoreRequest,
          onRowClickEvent: "openCover",
          style: {
            height: "76vh"
          },
        },
      },
    },
  ],
};
export { Score };
