import type { FieldsForm, FormStructure } from "@/static/components/Form/Form.interface";
import type { GridColumns } from "@/static/components/DataGrid/DataGrid.interface";
export const gridColumnsRateList: Array<GridColumns> = [
  {dataField: "amount", caption: "nameColumn.rate", dataType: "number", sortOrder:"desc", alignment:"left"},
  {dataField: "basic", caption: "nameColumn.view", dataType: "string", alignment:"left",
    customizeText: (cellInfo) => (cellInfo as any).value===true ? "Основной" : (cellInfo as any).value===false ? "Дополнительный" : "",
    allowHeaderFiltering: true, headerFilter: { dataSource: [{ text: "Основной", value: "true" }, { text: "Дополнительный", value: "false" }]},
  },
  {dataField: "RateName", caption: "nameColumn.typeService", dataType: "string", alignment:"left"},
  {dataField: "currency", caption: "nameColumn.currency", dataType: "string", alignment:"left"},
  {dataField: "unit", caption: "nameColumn.unit", dataType: "string", alignment:"left"},
  {dataField: "EndDate", caption: "nameColumn.dateTariffValidity", dataType: "date", alignment:"left"},
  {dataField: "disclaimer", caption: "nameColumn.additionalInformation", dataType: "string", alignment:"left"}
]

export const formStructureCalculator: FormStructure = [
  { titleGroup: "",
    fields: ["departurePoint", "destinationPoint", "departureDate", "cargoWeight", "unit", "volume", "preKerridge"],
    groups: [ { dependentField: "preKerridge", fields: ["cargoPickupAddress"] } ]
  },
]
export const fieldsFormCalculator: FieldsForm = {
  departurePoint: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_Линии",
    value: null,
    valueKey: "ПунктОтправки.Ref_Key",
    notDeletionMark: true,
    label: "form.label.departurePoint",
    showClearButton:true,
    rules: ["required"],
    filter: [
      // ["ПунктНазначения.Ref_Key", "=", "destinationPoint"],
      // "and",
      [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true],
    ],
    gridColumns: [
      {dataField: "ПунктОтправки.Description", caption: "nameColumn.station", dataType: "string", expand: "ПунктНазначения"},
      {dataField: "ПунктОтправки.Страна.Description", caption: "nameColumn.country", dataType: "string", expand: "ПунктОтправки/Страна"}
    ],
    distinct: true
    // nameField: "МассивЭлементов[0].СтанцияОтправления"
  },
  destinationPoint: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_Линии",
    value: null,
    valueKey: "ПунктНазначения.Ref_Key",
    notDeletionMark: true,
    label: "form.label.destinationPoint",
    showClearButton:true,
    rules: ["required"],
    filter: [
      // ["ПунктОтправки.Ref_Key", "=", "departurePoint"],
      // "and",
      [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true],
    ],
    gridColumns: [
      {dataField: "ПунктНазначения.Description", caption: "nameColumn.station", dataType: "string", expand: "ПунктОтправки"},
      {dataField: "ПунктНазначения.Страна.Description", caption: "nameColumn.country", dataType: "string", expand: "ПунктНазначения/Страна"}
    ],
    distinct: true
    // nameField: "МассивЭлементов[0].СтанцияНазначения"
  },
  departureDate: {
    typeDx: "dxDateBox",
    value: null,
    label: "form.label.departureDate",
    printLabel: "form.printLabel.startOfTransportation",
    rules: ["required"],
    moreFields: [],
    lessFields: ["arrivalDate"],
  },
  cargoWeight: {
    typeDx: "dxNumberBox",
    value: null,
    label: "form.label.cargoWeight",
    maskFormat: "00000.00",
    rules: ["required"],
    nameField: "МассивЭлементов[0].ВесГруза"
  },
  unit: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_ЕдиницыИзмерения",
    value: null,
    // valueKey: "view",
    // gridValues: [{Description: "кг."}, {Description: "т."}],
    label: "form.label.unit",
    showClearButton: true,
    rules: ["required"],
    filter: [
      [ "Description", "=", "кг" ],
      "or",
      [ "Description", "=", "т" ],
    ],
    gridColumns: [ {dataField: "Description", caption: "nameColumn.view", dataType: "string"} ],
    nameField: "TYPE"
  },
  volume: {
    typeDx: "dxNumberBox",
    value: null,
    label: "form.label.volume",
    maskFormat: "00000.00",
    rules: ["required"],
    nameField: "МассивЭлементов[0].ВесГруза"
  },
  preKerridge: {
    typeDx: "dxCheckBox",
    value: false,
    text: "form.text.preKerridge",
    nameField: "МассивЭлементов[0].НегабаритныйГруз"
  },
  cargoPickupAddress: {
    typeDx: "dxTextArea",
    value: "",
    label: "form.label.cargoPickupAddress",
    nameField: "Примечание",
    rules: ["required"],
    maxLength: 250
  },
}