import { createApp } from "vue";
import { createPinia } from "pinia";
import mitt from "mitt";
import App from "./App.vue";
import Evented from "@/Evented";
import router from "./router/index";
import themes from 'devextreme/ui/themes';
import "epcs-style-hub-dev/css/global.scss";

interface DemoOptions {
  baseUrl: string;
  element?: HTMLElement | string;
  locale?: string;
}

export default class SitronicsApp extends Evented {
  constructor(options: DemoOptions) {
    super();

    const bus = mitt();
    const app = createApp(App);

    app.provide("bus", bus);
    const routers = router(options.baseUrl);
    app.use(routers);
    app.use(createPinia());

    app.mount(options.element || "#app");
    themes.current("generic.light");
  }
}

new SitronicsApp({
  baseUrl: "/",
  locale: "ru",
  element: "#app",
});
