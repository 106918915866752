import type { StructuresModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import Start from "@/logic/pages/start";
import Login from "@/logic/pages/login";
import Profile from "@/logic/pages/profile";
import Calculator from "@/logic/pages/calculator";
import Inquiries from "@/logic/pages/inquiries";
import Score from "@/logic/pages/score";

const allStructures: StructuresModel = {
  Start,
  Login,
  Profile,
  Calculator,
  Inquiries,
  Score
};

export default allStructures;
