import config from "@/configs/config";
import {useUser} from "@/store/user";

async function createElementsOfCatalog (form:any) {
    const url = `${config.URL_1C}/hs/LK_Qw/CreateElementsOfCatalog?CatalogName=ГрузоотправителиГрузополучателиЛКК`
    const data = await fetch(url, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${useUser().getUser('access_token')}`,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(form)
    })
    if (!data.ok) { throw await data.json() }
    return await data.json()
}
export default {
    createElementsOfCatalog
}
