import config from "@/configs/config";
import {useUser} from "@/store/user";
const isRefKeyRegular = new RegExp(/(\w{8})-(\w{4})-(\w{4})-(\w{4})-(\w{12})/g)
const isNumberOrBooleanOrNullRegular = new RegExp(/^(?:-?\d+\.?\d*|true|false|null)$/)
function setFilter({valueKey, value}: { valueKey:string, value: string }) {
  if (!valueKey&&!value) { return }
  const isRefKey = value.match(isRefKeyRegular)
  return `${valueKey||"Ref_Key"} eq ${isRefKey ? `guid'${value}'` : value}`
}
function convertArrayToODataSting(array:[]):string {
  let odataQuery =  JSON.stringify(array)
    .replaceAll('[', '(').replaceAll(']', ')')
    .replaceAll('"', '').replaceAll(',', ' ')
    .replaceAll('=', 'eq')
    .replaceAll('<', 'lt')
    .replaceAll('>', 'gt');
  let matches = odataQuery.match(/(eq|ne|gt|ge|lt|le)\s+([^&)]+)/gi);
  if (matches?.length){
    for (let i = 0; i < matches.length; i++) {
      let match = matches[i];
      let parts = match.split(/\s+/);
      let value = parts[1].replace(/^'(.*)'$/, '$1');
      if (isRefKeyRegular.test(value)) {
        value = `guid'${value}'`
      } else if (!isNumberOrBooleanOrNullRegular.test(value)) {
        value = `'${value}'`
      }
      odataQuery = odataQuery.replace(match, `${parts[0]} ${value}`);
    }
  }
  return odataQuery
}

export const getTableData = function (paramsDropDown:any) {
  if (paramsDropDown?.gridValues && paramsDropDown?.gridValues?.length) {
    return [paramsDropDown.gridValues
      .find((v:any)=>v[paramsDropDown?.valueKey||"Ref_Key"] === paramsDropDown.value)]
  }
  if (!paramsDropDown.nameTable || !paramsDropDown.value || !paramsDropDown.value?.length) { return }
  if (paramsDropDown?.valueKey) {
    paramsDropDown.valueKey = paramsDropDown?.valueKey.replaceAll('.', '/');
  }
  //////////////////////////
  ////////If all the data is
  const url = `${config.URL_1C}/odata/standard.odata/${paramsDropDown.nameTable}`
  //////////////////////////
  /////////Generating filter
  let $filter = (Array.isArray(paramsDropDown.value)
    ? paramsDropDown.value.map((value:any)=>
      setFilter({valueKey: paramsDropDown.valueKey, value: String(value)})).join(" or ")
    : setFilter({valueKey: paramsDropDown.valueKey, value: String(paramsDropDown.value)}))
  //////////////////////////
  /////////Generating filter
  if (paramsDropDown?.filter){
    const resulFormFields = Object.assign(paramsDropDown.formFields||{},
      {customParameter: paramsDropDown.customParameter || '00000000-0000-0000-0000-000000000000'})
    let resultFilter = JSON.stringify(paramsDropDown.filter)
    resultFilter = resultFilter.replaceAll('.', '/');
    Object.keys(resulFormFields)
      .filter(field=> Array.from(new Set(resultFilter.match(/\b[^\d\W]+\b/g))).includes(field))
      .map(field=> resultFilter = resultFilter.replaceAll(field, resulFormFields[field]))
    $filter = $filter + " and " + convertArrayToODataSting(JSON.parse(resultFilter))
  }
  //////////////////////////
  //Generating $select and $expand
  const $select = [paramsDropDown.valueKey||"Ref_Key", ...paramsDropDown.gridColumns.map((column:any) => column.dataField.replaceAll('.', '/'))]
  const $expand = [...paramsDropDown.gridColumns.map((column:any) => column?.expand||'').filter((column:any) => !!column)]
  return fetch(
    url + `?$format=json&$filter=${$filter}&$select=${$select}&$expand=${$expand}`,
    { headers: {
        "Authorization": `Bearer ${useUser().getUser('access_token')}`,
        "Accept": "application/json"}})
    .then((response) => { return response.json() })
    .then(element => {
      return element.value
    })
    .catch(e => console.log("error", e))
}