import {
  type IStructureModel,
  StructuresModelType,
} from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {CoverModel} from "@/static/components/Cover/Cover.interface";
import type {FormModel} from "@/static/components/Form/Form.interface";
import type {AccordionModel} from "@/static/components/Accordion/Accordion.interface";
import type {CustomCardModel} from "@/static/components/CustomCard/CustomCard.interface";
import type {PopupModel} from "@/static/components/Popup/Popup.interface";
import {
  formStructureProfile, fieldsFormProfile,
  // formStructureResetPassword, fieldsFormResetPassword,
  accordionStructureRegisteredOrganizations,
  accordionStructureUnregisteredOrganizations,
  fieldsFormOrganization
} from "@/logic/pages/profile/data";

const Profile: IStructureModel = {
  structureType: StructuresModelType.sform,
  components: [
    {
      priority: 0,
      render: true,
      componentName: "CoverProfile",
      parent: "",
      col: 6,
      component: <CoverModel>{
        name: "Cover",
        props: {
          title: "title.personalInformation",
          style: { width: "-webkit-fill-available" },
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true,
          updateEvent: "updateCoverProfile",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "FormProfile",
      parent: "CoverProfile",
      col: 12,
      component: <FormModel>{
        name: "FormDx",
        props: {
          formStructure: formStructureProfile,
          fieldsForm: fieldsFormProfile,
        },
      },
    },
    // {
    //   priority: 0,
    //   render: true,
    //   componentName: "FormResetPassword",
    //   parent: "CoverProfile",
    //   col: 12,
    //   component: <FormModel>{
    //     name: "FormDx",
    //     props: {
    //       formStructure: formStructureResetPassword,
    //       fieldsForm: fieldsFormResetPassword,
    //       validateSubmitEvent: "submitCoverResetPassword",
    //       validateSubmitText: "btn.changePassword",
    //     },
    //   },
    // },
    {
      priority: 0,
      render: true,
      componentName: "CoverOrganizations",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          title: "title.allOrganizations",
          isExists: true,
          isFullScreen: false,
          isWithoutAnimation: true,
          style: { width: "-webkit-fill-available" },
          newEvent: "newOrganization",
          updateEvent: "updateOrganizations",
        },
      },
    },
    {
      priority: 1,
      render: true,
      componentName: "NotOrganizations",
      parent: "CoverOrganizations",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          type: "noData",
          style: {
            padding: "5rem"
          },
          url: "icon/corporate_wght200.svg",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "RegisteredOrganizations",
      parent: "CoverOrganizations",
      col: 12,
      component: <AccordionModel>{
        name: "Accordion",
        props: {
          accordionStructure: accordionStructureRegisteredOrganizations,
          title: "title.registeredOrganizations",
          titleField: "NAME",
          keyField: "COMPANYID",
          noData: "title.notRegisteredOrganizations",
          itemEvent: "readRegisteredOrganizations",
          itemText: "btn.open",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "UnregisteredOrganizations",
      parent: "CoverOrganizations",
      col: 12,
      component: <AccordionModel>{
        name: "Accordion",
        props: {
          accordionStructure: accordionStructureUnregisteredOrganizations,
          title: "title.unregisteredOrganizations",
          titleField: "NAME",
          keyField: "COMPANYID",
          noData: "title.notUnregisteredOrganizations",
          itemEvent: "readUnregisteredOrganizations",
          itemText: "btn.open",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "CoverOrganization",
      parent: "",
      col: 12,
      component: <CoverModel>{
        name: "Cover",
        props: {
          title: "title.organization",
          isExists: false,
          isFullScreen: false,
          isTextButton: false,
          style: { width: "-webkit-fill-available" },
          closeEvent: "closeCoverOrganization",
          resizeEvent: "resizeCoverOrganization",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "FormOrganization",
      parent: "CoverOrganization",
      col: 12,
      component: <FormModel>{
        name: "FormDx",
        props: {
          fieldsForm: fieldsFormOrganization,
          validateSubmitEvent: "submitCoverOrganization",
          validateSubmitText: "btn.save",
        },
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "PopupOrganization",
      parent: "",
      col: 12,
      component: <PopupModel>{
        name: "Popup",
        props: {},
      },
    },
    {
      priority: 0,
      render: true,
      componentName: "PopupTitle",
      parent: "PopupOrganization",
      col: 12,
      component: <CustomCardModel>{
        name: "CustomCard",
        props: {
          type: "p",
        },
      },
    },
  ],
};

export { Profile };
