import type Form from "@epcs/sitronics-spa/classes/form/Form";
import type Cover from "@/static/components/Cover/Cover";
import type TileView from "@/static/components/TileView/TileView";
import type FilePreviewType from "@/static/components/FilePreview/FilePreview";
import type { IInstanceVue } from "@/logic/views/instance/instance.interface";
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import Instance from "@/logic/views/instance/instance";
import {useLocale} from '@/store/locales'
import {useActiveCounterparty} from '@/store/activeCounterparty';

export default class Start extends Instance {
  CoverStart: Cover
  TileViewStart: TileView
  FerrySchedule: FilePreviewType
  activeCounterparty: any
  $l
  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    super(form, Vue, InstancePopup);
    this.$l = useLocale()
    this.activeCounterparty = useActiveCounterparty()
    this.CoverStart = <Cover>form.getComponentByName("CoverStart")
    this.TileViewStart = <TileView>form.getComponentByName("TileViewStart")
    this.FerrySchedule = <FilePreviewType>form.getComponentByName("FerrySchedule")
  }
  destroy() {}
}
