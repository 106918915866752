import type {FormStructure, FieldsForm} from "@/static/components/Form/Form.interface";
import type {AccordionStructure} from "@/static/components/Accordion/Accordion.interface";
export const formStructureProfile: FormStructure = [
  { titleGroup: "form.title.personalInformation", fields: ["lastName", "firstName", "middleName", "email", "phone"] },
]
export const fieldsFormProfile: FieldsForm = {
  email: {
    typeDx: "dxTextBox",
    value: null,
    mode: "email",
    label: "form.label.email",
    labelMode: "static",
    nameField: "EMAIL"
  },
  lastName: {
    typeDx: "dxTextBox",
    value: null,
    label: "form.label.lastName",
    labelMode: "static",
    nameField: "SURNAME"
  },
  firstName: {
    typeDx: "dxTextBox",
    value: null,
    label: "form.label.firstName",
    labelMode: "static",
    nameField: "NAME"
  },
  middleName: {
    typeDx: "dxTextBox",
    value: null,
    label: "form.label.middleName",
    labelMode: "static",
    nameField: "MIDNAME"
  },
  phone: {
    typeDx: "dxTextBox",
    value: null,
    mode: "phone",
    label: "form.label.phone",
    labelMode: "static",
    nameField: "MOBILEPHONE"
  },
}
// export const formStructureResetPassword: FormStructure = [
//   { titleGroup: "form.title.changePassword", fields: ["oldPassword", "password", "passwordConfirmation"] },
// ]
// export const fieldsFormResetPassword: FieldsForm = {
//   oldPassword: {
//     typeDx: "dxTextBox",
//     value: null,
//     mode: "password",
//     label: "form.label.oldPassword",
//     rules: ["required",
//       {type: "stringLength", min: 5, message: "form.validationRules.stringLength.password"}
//     ],
//   },
//   password: {
//     typeDx: "dxTextBox",
//     value: null,
//     mode: "password",
//     label: "form.label.password",
//     rules: ["required",
//       {type: "stringLength", min: 5, message: "form.validationRules.stringLength.password"}
//     ],
//   },
//   passwordConfirmation: {
//     typeDx: "dxTextBox",
//     value: null,
//     mode: "password",
//     label: "form.label.passwordConfirmation",
//     rules: ["required",
//       { type: "compare", dependentField: "password" },
//       { type: "stringLength", min: 5, message: "form.validationRules.stringLength.password"}
//     ],
//   }
// }
export const accordionStructureRegisteredOrganizations: AccordionStructure = [
  { field: "INN", label: 'form.label.INN' },
  { field: "KPP", label: 'form.label.KPP' },
  { field: "PHONE", label: 'form.label.phone', mode: 'phone' },
  { field: "POSTALADDR", label: 'form.label.addressCarrier' }
]
export const accordionStructureUnregisteredOrganizations: AccordionStructure = [
  { field: "STATUS_DK", label: 'form.label.status' },
  { field: "INN", label: 'form.label.INN' },
  { field: "KPP", label: 'form.label.KPP' },
  { field: "PHONE", label: 'form.label.phone', mode: 'phone' },
  { field: "POSTALADDR", label: 'form.label.addressCarrier' }
]
export const formStructureRegisteredOrganization: FormStructure = [
  { titleGroup: "form.title.organizationData", fields: [
    "countryRegistration", "registrationType", "nameOrganization",
      "fullNameOrganization", "INN", "address", "telephone", "OGRN",
      "OKPO", "KPP", "companyDirector"] },
]
export const formStructureUnregisteredOrganization: FormStructure = [
  { titleGroup: "form.title.organizationData", fields: [
    "countryRegistration", "registrationType", "nameOrganization",
      "fullNameOrganization", "INN", "address", "telephone", "OGRN",
      "OKPO", "KPP", "companyDirector","comment", "attachedFile"] },
]
export const fieldsFormOrganization: FieldsForm = {
  countryRegistration: {
    typeDx: "dxDropDownBox",
    nameTable: "Catalog_СтраныМира",
    value: null,
    label: "form.label.countryOfRegistration",
    showClearButton: true,
    gridColumns: [
      {dataField: "Description", caption: "nameColumn.country", dataType: "string"}
    ],
    nameField: "COUNTRY_ID"
  },
  registrationType: {
    typeDx: "dxDropDownBox",
    nameTable: "",
    value: null,
    valueKey: "view",
    gridValues: [{view: "Юридическое лицо"}, {view: "Физическое лицо"}],
    label: "form.label.typeOfRegistration",
    showClearButton: true,
    gridColumns: [ {dataField: "view", caption: "nameColumn.view", dataType: "string"} ],
    nameField: "TYPE"
  },
  nameOrganization: {
    typeDx: "dxTextBox",
    value: null,
    rules: ["required"],
    label: "form.label.nameOfTheOrganization",
    nameField: "NAME"
  },
  fullNameOrganization: {
    typeDx: "dxTextBox",
    value: null,
    rules: ["required"],
    label: "form.label.fullNameOfOrganization",
    nameField: "NAME_FULL"
  },
  INN: {
    typeDx: "dxNumberBox",
    value: null,
    rules: ["required", {type: 'stringLength', min: 10, max: 10}],
    label: "form.label.INN",
  },
  address: {
    typeDx: "dxTextBox",
    value: null,
    rules: ["required"],
    label: "form.label.addressOfActualLocation",
    nameField: "POSTALADDR"
  },
  telephone: {
    typeDx: "dxTextBox",
    value: null,
    mode: "phone",
    rules: ["required"],
    label: "form.label.phone",
    nameField: "PHONE"
  },
  OGRN: {
    typeDx: "dxNumberBox",
    value: null,
    rules: ["required"],
    label: "form.label.OGRN",
    nameField: "OGRN"
  },
  OKPO: {
    typeDx: "dxNumberBox",
    value: null,
    rules: ["required"],
    label: "form.label.OKPO",
    nameField: "OKPO"
  },
  KPP: {
    typeDx: "dxNumberBox",
    value: null,
    rules: ["required", {type: 'stringLength', min: 9, max: 9}],
    label: "form.label.KPP",
  },
  companyDirector: {
    typeDx: "dxTextBox",
    value: null,
    label: "form.label.companyDirector",
    nameField: "CompanyDirector"
  },
  attachedFile: {
    typeDx: "dxFileUploader",
    value: null,
    selectButtonText: "form.selectButtonText.file",
    labelText: "form.labelText.file",
    accept: ".pdf, .doc, .docx, image/*",
    maxFileSize: 5_000_000,
    nameField: ""
  },
  comment: {
    typeDx: "dxTextArea",
    value: null,
    label: "form.label.comment",
    rules: [],
    nameField: "COMMENT"
  },
}