export default {
EPCSButton: false,
EPCSButtonTittleBar: false,
EPCSInputText: false,
EPCSInputNumber: false,
EPCSInputTextArea: false,
EPCSCheckBox: false,
EPCSDataGridTemplateModel: false,
EPCSTextBlock: false,
EPCSDataGrid: false,
EPCSFooter: false,
EPCSToggle: false,
EPCSCalendar: false,
EPCSJoystick: false,
EPCSPdfPopup: false,
EPCSUploadBlock: false,
EPCSNotificationBlock: false,
EPCSMenuButton: false,
EPCSPopupMenu: false,
EPCSPopupNotif: false,
EPCSButtonList: false,
EPCSFormInput: false,
EPCSEditDataGrid: false,
EPCSFormHeader: false,
EPCSDirection: false,
EPCSTabs: false,
EPCSPopup: false,
EPCSInputSelect: false,
EPCSInputDate: false,
EPCSRadioButtonGroup: false,
EPCSMap: true,
EPCSListView: false,
EPCSProductionCard: false,
EPCSProductionCardHub: false,
EPCSCardProductionDetailed: false,
EPCSGallery: false,
EPCSCoordsDataGrid: false,
EPCSTreeList: false,
EPCSFilterTemplate: false,
EPCSFilterCheckbox: false,
EPCSFilterInput: false,
EPCSFilterRadio: false,
EPCSAccordion: false,
EPCSSwitch: false,
EPCSButtonGroup: false,
EPCSMultiSelect: false,
EPCSLabel: false,
EPCSTooltip: false,
EPCSObjectViewer: false,
EPCSUploadButton: false,
CustomCard: true,
Popup: true,
Popover: false,
DataGrid: true,
Cover: true,
Window: true,
FormDx: true,
Accordion: true,
TileView: true,
TreeView: true,
FilePreview: true,
Weather: true,
FormHistory: true,
}
