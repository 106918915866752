import type Form from "@epcs/sitronics-spa/classes/form/Form";
import type Cover from "@/static/components/Cover/Cover";
import type FormDx from "@/static/components/Form/Form";
import type CustomCard from "@/static/components/CustomCard/CustomCard";
import type DataGrid from "@/static/components/DataGrid/DataGrid";
import type { IInstanceVue } from "@/logic/views/instance/instance.interface";
import type { IEPCSPopup } from "@epcs/sitronics-spa/components/EPCSPopup/EPCSPopup.interface";
import Instance from "@/logic/views/instance/instance";
import {useLocale} from '@/store/locales'
import {useUser} from "@/store/user";
import {useActiveCounterparty} from '@/store/activeCounterparty';
import API from "@/api";
import { AppEvents } from "@epcs/sitronics-spa/enums/AppEvents";

export default class Calculator extends Instance {
  CoverCalculator: Cover
  FormCalculator: FormDx
  FormCalculate: CustomCard
  RateList: DataGrid
  SubmitRate: CustomCard
  activeCounterparty: any
  $l
  constructor(form: Form, Vue: IInstanceVue, InstancePopup: IEPCSPopup) {
    super(form, Vue, InstancePopup);
    this.$l = useLocale()
    this.activeCounterparty = useActiveCounterparty()
    this.CoverCalculator = <Cover>form.getComponentByName("CoverCalculator")
    this.FormCalculator = <FormDx>form.getComponentByName("FormCalculator")
    this.FormCalculate = <CustomCard>form.getComponentByName("FormCalculate")
    this.RateList = <DataGrid>form.getComponentByName("RateList")
    this.SubmitRate = <CustomCard>form.getComponentByName("SubmitRate")
    this.emittedEvents()
    this.mountPage()
  }
  mountPage () {
    this.RateList.setIsVisible(false)
    this.SubmitRate.setIsVisible(false)
    this.FormCalculate.setButtonItemOptions({
      text: this.$l.message("btn.calculateTheCost"),
      onClick: (e)=> this.Vue.bus.emit("calculateTheCost", e)
    })
    this.SubmitRate.setButtonItemOptions({
      text: this.$l.message("btn.createRequest"),
      type: "default",
      onClick: (e)=> this.Vue.bus.emit("submitRate", e)
    })
  }
  emittedEvents() {
    this.Vue.bus.on("calculateTheCost", async (event:any) => {
      const validate = this.FormCalculator.validate()
      if (validate?.isValid) {
        try {
          const response = await API.calculator.calculateTheCost({
              "departurePoint": this.FormCalculator?.formValues?.departurePoint,
              "destinationPoint": this.FormCalculator?.formValues?.destinationPoint,
              "cargoWeight": this.FormCalculator?.formValues?.cargoWeight,
              "unit": this.FormCalculator?.formValues?.unit,
              "volume": this.FormCalculator?.formValues?.volume,
              "preKerridge": this.FormCalculator?.formValues?.preKerridge,
              "cargoPickupAddress": this.FormCalculator?.formValues?.cargoPickupAddress,
              "departureDate": this.FormCalculator?.formValues?.departureDate,
            }, useUser().getUser('id'))
          const resultRateList = response?.["RateList"].map((item:any)=>{
            let disclaimer
            switch (this.$l.getLocale()) {
              case "ru": disclaimer = item?.["disclaimerRUS"];break
              case "en": disclaimer = item?.["disclaimerENG"];break
              case "zh": disclaimer = "";break
            }
            return { ...item, disclaimer }
          });
          if (resultRateList.length){
            this.RateList.setIsVisible(true)
            this.RateList.setDataSource(resultRateList)
          }
        } catch (e) {
          this.setMessage(this.$l.message("messages.calculateRates")||"","info")
        }
      }
    })
    this.Vue.bus.on("selectRate", async (event:any) => {
      const dataList = this.RateList.getDataInstance()
      const result = await dataList?.getSelectedRowsData()
      if (result) {
        this.SubmitRate.setIsVisible(!!result?.length)
      }
    })
    this.Vue.bus.on("submitRate", async (event:any) => {
      const dataList = this.RateList.getDataInstance()
      let result = await dataList?.getSelectedRowsData()
      if (result && result.length) {
        const rateList = result.map((item:any)=>{
          return {
            ["Rate_UID"]: item["Rate_UID"],
            ["amount"]: item["amount"]
          }
        })
        const form:any = {
          "departurePoint": this.FormCalculator?.formValues?.departurePoint,
          "destinationPoint": this.FormCalculator?.formValues?.destinationPoint,
          "preKerridge": this.FormCalculator?.formValues?.preKerridge,
          "cargoPickupAddress": this.FormCalculator?.formValues?.cargoPickupAddress,
          "cargoArray": [
            {
              // "cargoName": "product",
              // "cargoNameTNVED": "296affa5-e39a-11e6-80c9-ec086b042074",
              "cargoWeight": this.FormCalculator?.formValues?.cargoWeight,
              "unit": this.FormCalculator?.formValues?.unit,
              "volume": this.FormCalculator?.formValues?.volume,
              // "quantityPosition": 10
            }
          ],
          "departureDate": this.FormCalculator?.formValues?.departureDate,
          // "shipper": "c749c286-5ea2-11ee-9794-002590357fc4",
          // "consignee": "3c32f0c2-5ea3-11ee-9794-002590357fc4",
          // "contract": "5875afdb-47f9-488e-a182-5e4fe8dd2da6",
          "RateList": rateList
        }
        form['keycloakId'] = useUser().getUser('id')
        form['counterparty'] = this.activeCounterparty.getActiveCounterparty()
        try{
          const response = await API.ferryTransportation.submitForm(form)
          this.setMessage(this.$l.message("messages.applicationSavedAsDraft")||"")
          this.Vue.bus.emit(AppEvents.GetComponent, {
            location: { name: "inquiries", query:{request: response["Ref"]} },
          });
        }catch (e) {
          this.setMessage(this.$l.message("messages.submitFerryRequest")||"","error")
        }
      }
    })
    this.Vue.bus.on("updateFieldEvent", async (event:any) => {
      this.RateList.setIsVisible(false)
      this.SubmitRate.setIsVisible(false)
      const formValues = this.FormCalculator.getFormValues()
      switch (event.field) {
        case "destinationPoint": {
          const departurePoint = this.FormCalculator.getFieldsForm("departurePoint")
          if (departurePoint && "filter" in departurePoint) {
            if (formValues["destinationPoint"]){
              departurePoint.filter = [["ПунктНазначения.Ref_Key", "=", "destinationPoint"], "and", [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true]]
            } else { departurePoint.filter = [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true] }
          }
          break;
        }
        case "departurePoint":{
          const destinationPoint = this.FormCalculator.getFieldsForm("destinationPoint")
          if (destinationPoint && "filter" in destinationPoint) {
            if (formValues["departurePoint"]){
              destinationPoint.filter = [["ПунктОтправки.Ref_Key", "=", "departurePoint"], "and", [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true]]
            } else { destinationPoint.filter = [ "ПунктОтправки.ИспользуетсяВЗаявкахСГ", "=", true] }
          }
          break;
        }
      }
    })
  }
  destroy() {}
}
