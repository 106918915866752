import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type {CoverProps, ButtonEvent, ButtonEventTypes, ButtonText, ButtonTextTypes} from "./Cover.interface";

export default class Cover extends EPCSComponent {
  declare props:CoverProps
  constructor(component: IStructureComponentModel) {
    super(component);
  }
  fullScreenCover(): void {
    this.props.isExists = false;
    setTimeout(()=> {
      this.props.isExists = true;
      this.props.isFullScreen = true;
      this.props.isNew = true;
    }, 100)
  }
  openCover(): void {
    this.props.isExists = true;
    this.props.isFullScreen = false;
    this.props.isNew = false;
  }
  closeCover(): void {
    this.props.isExists = false;
    this.props.isFullScreen = false;
  }
  duplicateCover(): void {
    this.props.isExists = false;
    setTimeout(()=> {
      this.props.isExists = true;
      this.props.isNew = true;
      this.props.isFullScreen = true;
    }, 100)
  }
  resizeCover(): void {
    this.props.isFullScreen = !this.props.isFullScreen;
  }
  startLoad(): void {
    this.props.isLoaded = true;
  }
  stopLoad(): void {
    this.props.isLoaded = false;
  }
  getEventButton(typeButton:ButtonEventTypes|undefined):ButtonEvent|undefined {
    if (typeButton) {
      return this.props[typeButton]
    } else { return undefined }
  }
  setEventButton(typeButton:ButtonEventTypes|undefined, event:ButtonEvent) {
    if (typeButton) {
      this.props[typeButton] = event
    }
  }
  getTextButton(typeButton:ButtonTextTypes|undefined):ButtonText|undefined {
    if (typeButton) {
      return this.props[typeButton]
    } else { return undefined }
  }
  setTextButton(typeButton:ButtonTextTypes|undefined, text:ButtonText) {
    if (typeButton) {
      this.props[typeButton] = text
    }
  }
  getIsVisible ():CoverProps["isExists"] { return this.props.isExists }
  setIsVisible (value:CoverProps["isExists"]) { this.props.isExists = value }
  getIsWithoutAnimation ():CoverProps["isWithoutAnimation"] { return this.props.isWithoutAnimation }
  setIsWithoutAnimation (value:CoverProps["isWithoutAnimation"]) { this.props.isWithoutAnimation = value }
  getStyle ():CoverProps["style"] { return this.props.style }
  setStyle (value:CoverProps["style"]) { this.props.style = value }
  getStyleContent ():CoverProps["styleContent"] { return this.props.styleContent }
  setStyleContent (value:CoverProps["styleContent"]) { this.props.styleContent = value }
}
