import EPCSComponent from "@epcs/sitronics-spa/components/EPCSComponent/EPCSComponent";
import type { IStructureComponentModel } from "@epcs/sitronics-spa/interfaces/structure/Structure.interfaces";
import type { AccordionProps } from "./Accordion.interface";

export default class Accordion extends EPCSComponent {
  constructor(component: IStructureComponentModel) {
    super(component);
  }
  setAccordion(data: any, active: any): void {
    (this.props as AccordionProps).entities = data;
    (this.props as AccordionProps).activeEntity = active;
  }
}
