<template>
  <router-view
    :components="pullComponents"
    :form="activeForm"
    :metaData="metaData"
    :application="application"
  />
</template>

<script lang="ts" setup>
import {ref, onMounted, watch, provide} from "vue";
import Application from "@epcs/sitronics-spa/classes/application/Application";
import allStructures from "@/allStructures";
import { useRoute, useRouter } from "vue-router";
import type {
  FormComponent,
  IForm,
} from "@epcs/sitronics-spa/classes/form/Form.interface";
import { useAppEvents } from "@epcs/sitronics-spa/composition/useAppEvents";
import EPCSComponentsFactoryCustom from "@/static/components/EPCSComponentsFactoryCustom";
// import KeycloakController from "@/logic/controllers/AuthController";

const route = useRoute()
const router = useRouter();

import { useUser } from '@/store/user'
import { useCounterparties } from "@/store/counterparties";
import { useActiveCounterparty } from "@/store/activeCounterparty";

import config from "@/configs/config";
import Keycloak from "keycloak-js";
let initOptions = {
  url: config.URL_KEYCLOAK,
  realm: config.REALM_KEYCLOAK,
  clientId: config.CLIENT_KEYCLOAK }
let keycloak:Keycloak = new Keycloak(initOptions);
provide("keycloak", keycloak);
if (useUser().getUser() && !keycloak.token){
  keycloak.init({ onLoad: "login-required" })
    .then((auth) =>{
      console.log("auth", auth)
      useUser().setUser(Object.assign(useUser().getUser(),
        { access_token: keycloak.token,
          refresh_token: keycloak.refreshToken,
          expires_in: new Date((keycloak?.idTokenParsed?.exp||1)*1000||1000)
        }))
    })
    .catch((reason) =>{
      console.log("reason error", reason)
    });
}

const { clearBusEvents, initAppEvents, metaData } = useAppEvents();

let pullComponents = ref<FormComponent[]>([]);
let activeForm = ref<IForm>();

const pagesThatDoNotRequireAuthorization = ["login", "registration"]

const refreshFormView = () => {
  activeForm.value = application.getFormByName(
    String(route.meta.logicName)
  );
  if (activeForm.value) {
    pullComponents.value = activeForm.value.getFormForVue();
  } else {
    router.push({ name: "start"})
  }
};

let application = new Application(allStructures, EPCSComponentsFactoryCustom);

onMounted(async () => {
  router.isReady().then(async () => {
    if (!useUser().getUser()) {
      if (!pagesThatDoNotRequireAuthorization.includes(String(route.name))){
        return router.push({ name: "login"})
      }
    } else if (useUser().getUser("expires_in")) {
      const timeout = new Date(useUser().getUser("expires_in")).valueOf() - new Date().valueOf()
      console.log("timeout", timeout);
      await updateRefreshToken()
      setInterval(updateRefreshToken, 60000)
    }
    if (!activeForm.value) refreshFormView();
  });
  initAppEvents();
});

watch(
  () => route.name,
  () => {
    if (!useUser().getUser()) {
      if (!pagesThatDoNotRequireAuthorization.includes(String(route.name))){
        return router.push({ name: "login"})
      }
    }
    refreshFormView();
    clearBusEvents();
  }
);
window.addEventListener("focus", () => { updateRefreshToken() })
async function updateRefreshToken () {
  if (!useUser().getUser()) { return }
  try {
    console.log("updateRefreshToken");
    keycloak.updateToken(50).then((refreshed:any)=>{
      console.log("updateRefreshToken ok");
      if (refreshed){
        useUser().setUser(Object.assign(useUser().getUser(),
          { access_token: keycloak.token,
            refresh_token: keycloak.refreshToken,
            expires_in: new Date((keycloak?.idTokenParsed?.exp||1)*1000||1000)
          }))
        console.log('refreshed '+new Date());
      }else {
        console.log('not refreshed '+new Date());
      }
    }).catch((e) => {
      console.error('Failed to refresh token '+new Date(), e);
    });
    // const keycloak = new KeycloakController
    // const userToken:any = await keycloak.updateRefreshToken(useUser().getUser("refresh_token"))
    // useUser().setUser(Object.assign(useUser().getUser(), userToken))
  } catch (e:any) {
    if (e.error_description === "Token is not active" || e.error_description === "Session not active" || e.error_description === "Invalid refresh token") {
      useUser().setUser()
      useCounterparties().setCounterparties()
      useActiveCounterparty().setActiveCounterparty()
      location.reload()
    }
  }
}
</script>

<style lang="scss">
@import "./styles/scss";
@import "./styles/main.scss";
</style>
